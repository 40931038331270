import React, { useContext, useState } from 'react';
// AUTH
import { AuthContext } from './contexts/AuthContext';
import { GlobContext } from './contexts/GlobContext';
// REACT ROUTER
import { Outlet } from "react-router-dom";
// PARTIALS
import Header from './partials/header';
// REACT SPINNER
import { Watch } from 'react-loader-spinner';
// CSS
import 'bootstrap/dist/css/bootstrap.min.css';

function Main() {
  // AUTH, STATE, NAVIGATION
  const { authobj, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { wait, setWait, waitPercent, setWaitPercent } = useContext(GlobContext);
  
  return (
    <>
      { authobj && authobj.token && window.location.pathname != '/admin' && window.location.pathname != '/admin/' && window.location.pathname.startsWith('/admin') ? <Header /> : ''}
      { wait ? 
        <div id="wait">
          { waitPercent !== null ?
            <div className="percent">{waitPercent}%</div>
          : '' }
          <Watch height="80" width="80" radius="48" color="#44B2EB" ariaLabel="watch-loading" wrapperStyle={{}} wrapperClassName="" visible={true} />
        </div>
      : '' }
      <Outlet />
    </>
  );
}

export default Main;