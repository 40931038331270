import React, { useState, useContext, useEffect } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// AXIOS
import axios from 'axios';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { CoPresentOutlined } from '@mui/icons-material';

export function useHelpers() {
    // AUTH, STATE, NAVIGATION
    const { authobj, setAuthobj, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
    let navigate = useNavigate();
    // DATA
    // SWEETALERT
    const MySwal = withReactContent(Swal);

    useEffect(() => {
    }, [globalRefresh]);

    const getDayName = (dateString) => {
        const date = new Date(dateString);
        const options = { weekday: 'long' }; // Retrieve full name of the day
        const dayName = date.toLocaleDateString('it-IT', options); // 'it-IT' for Italian locale
        return dayName.charAt(0).toUpperCase() + dayName.slice(1);
    }

    const copyOnClipboard = (str, message) => {
        navigator.clipboard.writeText(str);
        Swal.fire({
            icon: 'success',
            title: 'Testo copiato',
            html: message
        });
    }

    const getOccupationClass = (perc) => {
        if (perc == 0)
            return 'occ_zero';
        else if (perc <= 25)
            return 'occ_low';
        else if (perc <= 50)
            return 'occ_medium'
        else if (perc <= 75)
            return 'occ_high';
        else if (perc <= 95)
            return 'occ_veryhigh';
        else if (perc == 100)
            return 'occ_full';
    }

    const handleReservationPayment = (reservation) => {
        const { value: formValues } = MySwal.fire({
            html: (
                <div>
                    <h2>Gestisci pagamento prenotazione</h2>
                    <br />
                    <Row>
                      <Col>
                        <label>Stato pagamento</label>
                        <select name="pagamento_effettuato" id="pagamento_effettuato">
                          <option value="1" selected={reservation.pagamento_effettuato === "1"}>Pagata</option>
                          <option value="0" selected={reservation.pagamento_effettuato === "0"}>Da pagare</option>
                        </select>
                      </Col>
                    </Row>
                    <Row>
                        <Col>
                            { !reservation.pagamento_importo ? <label>Importo pagamento (€)</label> : '' }
                            <input type={reservation.pagamento_importo ? 'hidden' : 'number'} step="5" name="pagamento_importo" id="pagamento_importo" value={reservation.pagamento_importo} />
                        </Col> 
                    </Row>
                    <Row>
                      <Col>
                        <label>Metodo di pagamento</label>
                        <select name="pagamento_tipo" id="pagamento_tipo">
                            <option value="">- Seleziona metodo pagamento -</option>
                            <option value="online" selected={reservation.pagamento_tipo === "online"}>Online</option>
                            <option value="standby" selected={reservation.pagamento_tipo === "standby"}>Standby</option>
                            {/* <option value="contanti" selected={reservation.pagamento_tipo === "contanti"}>Contanti</option> */}
                            <option value="pos" selected={reservation.pagamento_tipo === "pos"}>POS</option>
                            <option value="bonifico" selected={reservation.pagamento_tipo === "bonifico"}>Bonifico</option>
                        </select>
                      </Col>
                    </Row>
                </div>
            ),
            width: '800px',
            preConfirm: () => {
              return [
                document.getElementById("pagamento_effettuato").value,
                document.getElementById("pagamento_importo").value,
                document.getElementById("pagamento_tipo").value
              ];
            },
            showDenyButton: false,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "Aggiorna prenotazione",
            cancelButtonText: "Annulla",
            // confirmButtonColor: "#12275d",
            // cancelButtonColor: "#12275d"
        }).then((result) => {
            if (result.isConfirmed) {
                let pagamento_effettuato = result.value[0];
                let pagamento_importo = result.value[1];
                let pagamento_tipo = result.value[2];
                let pagamento_data = new Date().toISOString().substr(0, 10)+' '+new Date().toISOString().substr(11, 8);

                if (pagamento_effettuato && pagamento_tipo) {
                    axios.post(window.SERVER_URL+'reservations/set_payment', { id: reservation.id, pagamento_effettuato, pagamento_importo, pagamento_data, pagamento_tipo }, {
                        headers: {
                        'Access-Control-Allow-Origin': '*',
                        'Authorization': 'Bearer '+authobj.token
                        }
                    }).then(res => {
                        if (res.data.success) {
                            Swal.fire({
                                icon: 'success',
                                title: 'Pagamento aggiornato',
                                html: "La prenotazione è stata aggiornata con i dati di pagamento"
                            }).then(res => {
                                setGlobalRefresh(1);
                            });
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Errore',
                                text: "Non è stato possibile aggiornare la prenotazione"
                            });
                        }
                    });
                } else {
                    Swal.fire({
                        icon: 'warning',
                        title: 'Attenzione',
                        text: "Devi specificare tutti i campi"
                    });
                }
            }
        });
    }

    const handleReservationDelete = (reservation_id, titolare_email) => {
        console.log(authobj);
        // se authobj allora sto in admin
        Swal.fire({
            icon: 'warning',
            title: 'Sei sicuro di voler annullare questa prenotazione?',
            html: authobj && authobj.token ? "La prenotazione verrà messa nel cestino, potrai consultarla o recuperarla in futuro" : "Assicurati di aver letto i <b>termini e condizioni</b> sul nostro sito <b>prima</b> di annullare la tua prenotazione",
            showDenyButton: true,
            showCancelButton: authobj ? false : true,
            confirmButtonText: 'Conferma',
            denyButtonText: 'Ci ho ripensato',
            cancelButtonText: 'Leggi termini e condizioni',
            cancelButtonColor: "#314491", 
            confirmButtonColor: "#314491",
            cancelButtonColor: "#cb4f2c",
            width: '600',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post(window.SERVER_URL+'reservations/delete', { id: reservation_id, titolare_email: titolare_email }, { headers: {
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': (authobj && authobj.token ? 'Bearer '+authobj.token : '')
                    }}).then(res => {
                        if (res.data.success) {
                            let info = null;
                            if (typeof res.data.voucher !== 'undefined') {
                                if (res.data.voucher == 50 || res.data.voucher == 100)
                                    info = `Secondo i termini e condizioni accettati, è stato automaticamente generato un <b>buono del ${res.data.voucher}% del valore dell'escursione</b>, che ti è stato inviato al tuo indirizzo e-mail`;
                                else {
                                    info = `Secondo i termini e condizioni accettati, purtroppo non hai diritto né alla riprogrammazione dell'escursione, né al rimborso del buono o dell'importo speso`;
                                }
                            }
                            Swal.fire({
                                icon: 'success',
                                title: 'Prenotazione annullata',
                                html: "La prenotazione è stata correttamente annullata"+(info ? '.<br><br>'+info : '')
                            }).then((result) => {
                                setGlobalRefresh(1);
                                if (!authobj)
                                    navigate('/');
                            });
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Si è verificato un errore',
                                html: "Non è stato possibile annullare la prenotazione, riprova per favore"
                            });
                        }
                    });
                } else if (result.isDismissed) {
                    window.open(window.TERMS_URL);
                }
        });
    }

    const calcAmount = (reservation) => {
        let tmp_amount = reservation.slot.slot_type.prezzo_guidatore * reservation.utenti.guidatori + reservation.slot.slot_type.prezzo_passeggero * reservation.utenti.passeggeri;
        if (reservation.slot.slot_type.codice.toLowerCase().includes('trenino'))
            tmp_amount = 160;
        let amount = tmp_amount;    // il costo standard
        if (reservation.voucher && reservation.coupon) {    // se la prenotazione ha un voucher da considerare
          if (reservation.coupon.unit == 'euro') {
            tmp_amount -= parseInt(reservation.coupon.amount);  // il prezzo scontato
          } else if (reservation.coupon.unit == 'percent') {
            tmp_amount = tmp_amount - (tmp_amount * (parseInt(reservation.coupon.amount) / 100));   // il prezzo scontato
          }
        }
        if (tmp_amount < 0) // se con lo sconto il totale dovesse venire < 0, allora pago 0
            tmp_amount = 0;
        return [amount, tmp_amount];
    }

    const handlePayment = (reservation_id, amountToPay) => {
        axios.post(window.SERVER_URL+'reservations/pay', { id: reservation_id, prezzo: amountToPay }, {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        }).then(res => {
            console.log(res.data);
            if (res.data.id) {
                window.location.href=res.data.hosted_payment.payment_url;
            } else {
                Swal.fire({
                icon: 'error',
                title: 'Si è verificato un errore',
                html: `Non è stato possibile inizializzare il pagamento
                        <div style="display: block; background: #eee; padding: 15px; border-radius: 15px; margin: 20px 0;">
                            <b>Errore:</b><br/>
                            ${res.data}
                        </div>
                        Riprova per favore`,
                });
            }
        });
    }

    const sendMail = (reservation_id) => {
        axios.post(window.SERVER_URL+'reservations/get', { id: reservation_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            console.log(res.data);
            window.open(`mailto:${res.data.titolare_email}?subject=EscursioniMotoslitte.it - Aggiornamento prenotazione ${res.data.codice}`);
          });
    }

    return { getDayName, copyOnClipboard, getOccupationClass, handleReservationPayment, handleReservationDelete, calcAmount, handlePayment, sendMail };
}