import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm, useFieldArray } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminPaylinksAdd() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, control, handleSubmit, setValue, getValues, watch, reset, formState: { errors } } = useForm();

  useEffect(() => {
  }, []);

  const onSubmit = (form_data) => {
    console.log(form_data);
    axios.post(window.SERVER_URL+'reservations/payment_link', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }
    }).then(res => {
      console.log(res.data);
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Link inviato',
          html: "Il link di pagamento è stato correttamente inviato"
        }).then(res => {
          navigate('/admin/dashboard');
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Si è verificato un errore',
          html: "Impossibile inviare il link di pagamento<br />Riprova per favore",
          confirmButtonColor: "#314491",
          confirmButtonText: "Ok"
        });
      }
    });
  }
  
  return (
    <Container className="content admin">
      <Row className="page_title">
        <Col>
          <h1>Crea link di pagamento</h1>
        </Col>
      </Row>
      <Row>
        <Col className='tab_wrapper'>
          <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Row className='panel'>
              <Col className="custom_form_wrapper">
                <Row>
                  <Col>
                    <label>Importo pagamento (€)</label>
                    <input type="number" {...register("importo", { required: true })} />
                  </Col>
                  <Col>
                    <label>Motivazione</label>
                    <input type="text" placeholder="Motivazione" {...register("motivazione", { required: true })} />
                  </Col>
                  <Col>
                    <label>Indirizzo e-mail a cui inviare il link</label>
                    <input type="email" placeholder="Indirizzo e-mail" {...register("email", { required: true })} />
                  </Col>
                </Row>
                <Button type="submit" variant="success" className="waste_btn">Invia link pagamento</Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  );
  
}

export default AdminPaylinksAdd;