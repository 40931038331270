import React, { useState, useEffect } from 'react';
// REACT DRAFT WYSIWYG
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const WYSIWYGEditor = ({ value, onChange }) => {
    const [editorState, setEditorState] = useState(() => {
        let parsedContent = null;
        // Check if value is a valid string and parse it
        if (value && typeof value === 'string') {
            try {
                parsedContent = JSON.parse(value);
                // Only return EditorState if the content is valid
                if (parsedContent && parsedContent.blocks) {
                    return EditorState.createWithContent(convertFromRaw(parsedContent));
                }
            } catch (error) {
                console.error('Error parsing RawDraftContentState:', error);
            }
        }
        // Return an empty editor state if no valid value was found
        return EditorState.createEmpty();
    });

    const handleEditorStateChange = (state) => {
        setEditorState(state);
        // Serialize the editor's content to a raw format and send it back to react-hook-form
        const rawContent = JSON.stringify(convertToRaw(state.getCurrentContent()));
        onChange(rawContent); // Send the raw content back to react-hook-form
    };

    return (
        <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorStateChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
            localization={{
                locale: 'it',
            }}
        />
    );
};

export default WYSIWYGEditor;
