import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { Controller, useForm, useFieldArray } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT DRAFT WYSIWYG
import WYSIWYGEditor from '../../partials/WYSIWYGEditor';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminReservationsAdd() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, control, handleSubmit, setValue, getValues, watch, reset, formState: { errors } } = useForm();
  const { fields: d_fields, append: d_append, prepend: d_prepend, remove: d_remove, swap: d_swap, move: d_move, insert: d_insert } = useFieldArray({
    control,
    name: "d_clients"
  });
  const { fields: p_fields, append: p_append, prepend: p_prepend, remove: p_remove, swap: p_swap, move: p_move, insert: p_insert } = useFieldArray({
    control,
    name: "p_clients"
  });
  // DATA
  const [slot, setSlot] = useState(null);
  const [reservation, setReservation] = useState(null);
  const [partners, setPartners] = useState(null);

  useEffect(() => {
    axios.post(window.SERVER_URL+'voucher_partners/list', {}, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(res => {
      console.log(res.data);
      setPartners(res.data);

      // STO AGGIUNGENDO UNA PRENOTAZIONE
      if (state && state.slot_id) {
        axios.post(window.SERVER_URL+'slots/get', { id: state.slot_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          console.log(res.data);
          setSlot(res.data);
          setValue('reservation[slot_id]', state.slot_id);
        });
      } else if (state && state.reservation_id) {  // STO MODIFICANDO UNA PRENOTAZIONE
        axios.post(window.SERVER_URL+'reservations/get', { id: state.reservation_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          console.log(res.data);
          setReservation(res.data);
          setSlot(res.data.slot);

          // Setto i campi della prenotazione
          let xfields = {};
          for (const [key, val] of Object.entries(res.data))
            if (typeof val !== 'object')
              xfields['reservation['+key+']'] = val;

          reset(xfields);

          // Setto i campi dei partecipanti
          if (res.data.clients) {
            d_remove();
            p_remove();

            res.data.clients.forEach((client, i) => {
              // for (const [key, val] of Object.entries(client))
                // if (typeof val !== 'object')
                //   xfields['clients['+i+']['+key+']'] = val;

              if (client.client_type_id == 1) { // Guidatore
                add_d_client(client.nome, client.cognome, client.eta);
              }
              else if (client.client_type_id == 2) { // Passeggero
                add_p_client(client.nome, client.cognome, client.eta);
              }
            });
          }
        });
      } else {
        navigate('/admin/reservations');
      }
    });    
  }, []);

  const add_d_client = (firstname, lastname, age) => {
    d_append({
      nome: firstname ? firstname : null,
      cognome: lastname ? lastname : null,
      eta: age ? age : null
    });
  }

  const add_p_client = (firstname, lastname, age) => {
    p_append({
      nome: firstname ? firstname : null,
      cognome: lastname ? lastname : null,
      eta: age ? age : null
    });
  }

  const fillClient = (type, i) => {
    console.log(getValues());
    console.log(i);
    setValue(`${type}_clients[${i}][nome]`, getValues('reservation[titolare_nome]'));
    setValue(`${type}_clients[${i}][cognome]`, getValues('reservation[titolare_cognome]'));
    setValue(`${type}_clients[${i}][eta]`, getValues('reservation[titolare_eta]'));
  }

  const onSubmit = (form_data) => {
    let tmp_clients = [];
    let clients_ok = true;
    // Inserisco nell'elenco dei clienti i GUIDATORI
    if (form_data.d_clients && form_data.d_clients.length) {
      form_data.d_clients.forEach((c) => {
        let tmp_c = c;
        if (!tmp_c.nome || !tmp_c.cognome || !tmp_c.eta)
          clients_ok = false;
        tmp_c.client_type_id = 1; // Tipologia: Guidatore
        tmp_clients.push(tmp_c);
      });
    }
    // Inserisco nell'elenco dei clienti i PASSEGGERI
    if (form_data.p_clients && form_data.p_clients.length) {
      form_data.p_clients.forEach((c) => {
        let tmp_c = c;
        if (!tmp_c.nome || !tmp_c.cognome || !tmp_c.eta)
          clients_ok = false;
        tmp_c.client_type_id = 2; // Tipologia: Passeggero
        tmp_clients.push(tmp_c);
      });
    }
    if (tmp_clients.length)
      form_data['clients'] = tmp_clients;
    delete form_data['d_clients'];
    delete form_data['p_clients'];

    // se sto creando la prenotazione (non ho l'ID) e ho messo pagamento effettuato
    if (!form_data['reservation']['id'] && form_data['reservation']['pagamento_effettuato'] == '1') {
      form_data["reservation"]["pagamento_effettuato"] = "1";
      form_data["reservation"]["pagamento_data"] = new Date().toLocaleString('sv-SE').replace('T', ' ');
    }

    console.log(form_data);
    if (tmp_clients.length) {
      if (clients_ok) {
        axios.post(window.SERVER_URL+'reservations/save', form_data, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }
        }).then(res => {
          console.log(res.data);
          if (res.data.success) {
            Swal.fire({
              icon: 'success',
              title: 'Prenotazione salvata',
              html: "La prenotazione è stata salvata"
            }).then(res => {
              if (state && state.slot_id)
                navigate('/admin/slots/view', { state: { slot_id: state.slot_id }});
              else if (reservation && reservation.slot)
                navigate(`/admin/dashboard/${reservation.slot.inizio.substr(0,10)}`);
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Si è verificato un errore',
              html: "La tua prenotazione non è stata salvata<br />Riprova per favore",
              confirmButtonColor: "#314491",
              confirmButtonText: "Ok"
            });
          }
        });
      } else {  // validazione clienti non è andata a buon fine
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non hai caricato tutti i valori obbligatori per i partecipanti"
        });
      }
    } else {  // validazione numero clienti non è andata a buon fine
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Devi inserire almeno un partecipante nell'escursione"
      });
    }
  }
  
  return (
    <Container className="content admin">
      { slot ? 
        <>
          <Row className="page_title">
            <Col>
              { reservation ? 
                <h1>Prenotazione <span>{ reservation.codice }</span></h1>
              :
                <h1>Nuova prenotazione</h1> }
            </Col>
          </Row>
          <Row>
            <Col className='tab_wrapper'>
              <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" value="admin" {...register("side")} />
                { reservation ? 
                  <input type="hidden" {...register("reservation[id]", { required: true })} />
                : 
                  <input type="hidden" {...register("reservation[slot_id]")} />
                }
                <Row className='panel'>
                  <Col className="custom_form_wrapper">
                    <Row className='admin_detail'>
                      { reservation ? 
                        <>
                          <Col lg={1}>
                            ID<br />
                            <b>{ reservation.id }</b>
                          </Col>
                          <Col>
                            Effettuata in data<br />
                            { reservation.previous ?
                              <b>{ reservation.previous.created_at ? reservation.created_at.substr(0, 10).split("-").reverse().join("/")+' '+reservation.previous.created_at.substr(11,5) : '-' }</b>
                            :
                              <b>{ reservation.created_at ? reservation.created_at.substr(0, 10).split("-").reverse().join("/")+' '+reservation.created_at.substr(11,5) : '-' }</b>
                            }
                          </Col>
                        </>
                      : '' }
                      <Col lg={4}>
                        Tipo escursione: <b>{ slot.slot_type.descrizione }</b><br />
                        Data e ora escursione: <b>{ slot.inizio.substr(0, 10).split("-").reverse().join("/")+' '+slot.inizio.substr(11,5) }</b>
                      </Col>
                      <Col>
                        Guidatori disponibili: <b>{ slot.utenti.guidatori.disponibili }</b><br />
                        Passeggeri disponibili: <b>{ slot.utenti.passeggeri.disponibili }</b>
                      </Col>
                      { !reservation || !reservation.deleted_at ?
                        <Col lg={2}>
                          <Button onClick={() => navigate('/admin/slots/view', { state: { slot_id: (reservation ? reservation.slot.id : state.slot_id) } }) }><DynamicIcon iconName='FaCalendarDay' /> Vai all'escursione</Button>
                        </Col>
                      : '' }
                    </Row>
                    { reservation ? // se sto editando una prenotazione, faccio vedere lo stato del pagamento
                      <Row className='admin_detail'>
                        <Col>
                          Stato<br />
                          <div className={'tag '+reservation.stato} style={{'display':'inline-block','margin':'0 5px 0 0'}}></div> <b>{ reservation.stato == 'approved' ? 'PAGATA' : (reservation.stato == 'suspended' ? 'NON PAGATA' : 'IN FASE DI CREAZIONE') }</b>
                        </Col>
                        <Col>
                          Importo da pagare<br />
                          <b>{ reservation.pagamento_importo ? parseInt(reservation.pagamento_importo)+' €' : '-' }</b>
                        </Col>
                        <Col>
                          Data pagamento<br />
                          <b>{ reservation.pagamento_data ? reservation.pagamento_data.substr(0, 10).split("-").reverse().join("/")+' '+reservation.pagamento_data.substr(11,5) : '-' }</b>
                        </Col>
                        <Col>
                          Tipo pagamento<br />
                          <b>{ reservation.pagamento_tipo ? reservation.pagamento_tipo : '-' }</b>
                        </Col>
                        { reservation.stato == 'approved' && reservation.pagamento_tipo == 'online' ?
                          <Col>
                            ID transazione PayPlug<br />
                            <b>{ reservation.pagamento_id_transazione ? reservation.pagamento_id_transazione : '-' }</b>
                          </Col>
                        : '' }
                        <Col>
                          Buono regalo<br />
                          <b>{ reservation.voucher ? reservation.voucher+(reservation.coupon ? ' [-'+reservation.coupon.amount+(reservation.coupon.unit == 'euro' ? '€' : '%')+']' : '') : 'No' }</b>
                        </Col>
                      </Row>
                    : '' }
                    { reservation && reservation.previous ?   // se sto editando una prenotazione ed è stata spostata
                      <>
                        <Row className='admin_detail alert'>
                          <Col style={{'padding':'10px 0'}}>
                            Spostata in data<br />
                            <b>{ reservation.created_at ? reservation.created_at.substr(0, 10).split("-").reverse().join("/")+' '+reservation.created_at.substr(11,5) : '-' }</b>
                          </Col>
                          <Col style={{'padding':'10px 0'}}>
                            Data e ora precedenti<br />
                            <b>{ reservation.previous.slot ? reservation.previous.slot.inizio.substr(0, 10).split("-").reverse().join("/")+' '+reservation.previous.slot.inizio.substr(11,5) : '-' }</b>
                          </Col>
                        </Row>
                        <br />
                      </>
                    : '' }
                    <h4>Dati titolare prenotazione</h4>
                    <Row>
                      <Col>
                        <label>Nome</label>
                        <input type="text" placeholder="Nome" {...register("reservation[titolare_nome]", { required: true })} />
                      </Col>
                      <Col>
                        <label>Cognome</label>
                        <input type="text" placeholder="Cognome" {...register("reservation[titolare_cognome]", { required: true })} />
                      </Col>
                      <Col>
                        <label>Cellulare</label>
                        <input type="text" placeholder="Cellulare" {...register("reservation[titolare_telefono]", { required: true })} />
                      </Col>
                    </Row>
                    <Row style={{'marginTop':'10px'}}>
                      <Col>
                        <label>E-mail</label>
                        <input type="email" placeholder="E-mail" {...register("reservation[titolare_email]", { required: true })} />
                      </Col>
                      <Col lg={2}>
                        <label>Età</label>
                        <input type="number" min="18" max="100" step="1" placeholder="Età" {...register("reservation[titolare_eta]")} />
                      </Col>
                      <Col lg={2}>
                        <label>Genere</label>
                        <select {...register("reservation[titolare_genere]")}>
                          <option value="">-</option>
                          <option value="M">Uomo</option>
                          <option value="F">Donna</option>
                          <option value="X">Preferisco non specificare</option>
                        </select>
                      </Col>
                      <Col>
                        <label>Come ci hai trovato?</label>
                        <select {...register("reservation[marketing_info]")}>
                          <option value="">-</option>
                          <option value="Volantini">Volantini</option>
                          <option value="Già cliente">Già cliente</option>
                          <option value="Internet">Internet</option>
                          <option value="Passaparola">Passaparola</option>
                          <option value="Fiera o evento">Fiera / evento</option>
                        </select>
                      </Col>
                    </Row>
                    <Row style={{'marginTop':'10px'}}>
                      <Col>
                        <label>Portale partner</label>
                        <select {...register("reservation[voucher_partner_id]")}>
                          <option value="">-</option>
                          { partners && partners.length ? partners.map((partner) => 
                            <option key={partner.id} value={partner.id}>{ partner.nome }</option>
                          ) : '' }
                        </select>
                      </Col>
                      <Col>
                          <label>Codice prenotazione portale</label>
                          <input type="text" placeholder="Cod. prenotazione portale" {...register("reservation[codice_voucher_portale]")} />
                      </Col>
                    </Row>
                    <hr />
                    <h4>Dati partecipanti</h4>
                    <Row>
                      <Col className='action_bar bottom' style={{'textAlign':'center'}}>
                        { d_fields.length < slot.utenti.guidatori.disponibili ?
                          <Button onClick={() => { add_d_client() }}><DynamicIcon iconName='FaPlus' /> Aggiungi guidatore</Button>
                        : '' }
                        { p_fields.length < slot.utenti.passeggeri.disponibili ?
                          <Button onClick={() => { add_p_client() }}><DynamicIcon iconName='FaPlus' /> Aggiungi passeggero</Button>
                        : '' }
                      </Col>
                    </Row>
                    <div id='persons_wrapper' style={{'marginTop':'40px'}}>
                      {/* GUIDATORI */}
                      { d_fields.map((item, i) => (
                        <Row className='person_row'>
                          <p><DynamicIcon iconName='GiSteeringWheel' size="24" /> GUIDATORE <span onClick={() => d_remove(i) }><DynamicIcon iconName='ImCross' size="20" /></span></p>
                          <Col>
                            <label style={{'marginRight':'20px'}}>Nome</label>
                            <input type="text" placeholder="Nome" {...register(`d_clients[${i}][nome]`)} />
                          </Col>
                          <Col>
                            <label>Cognome</label>
                            <input type="text" placeholder="Cognome" {...register(`d_clients[${i}][cognome]`)} />
                          </Col>
                          <Col>
                            <label>Età</label>
                            <input type="number" min="1" max="100" step="1" placeholder="Età" {...register(`d_clients[${i}][eta]`)} />
                          </Col>
                          <Col>
                            <a className='hint_link' onClick={() => fillClient('d', i)}><DynamicIcon iconName='FaHandPointUp' /> Usa dati titolare</a>
                          </Col>
                        </Row>
                      ))}
                      {/* PASSEGGERI */}
                      { p_fields.map((item, i) => (
                        <Row className='person_row'>
                          <p><DynamicIcon iconName='MdEventSeat' size="24" /> PASSEGGERO <span onClick={() => p_remove(i) }><DynamicIcon iconName='ImCross' size="20" /></span></p>
                          <Col>
                            <label style={{'marginRight':'20px'}}>Nome</label>
                            <input type="text" placeholder="Nome" {...register(`p_clients[${i}][nome]`)} />
                          </Col>
                          <Col>
                            <label>Cognome</label>
                            <input type="text" placeholder="Cognome" {...register(`p_clients[${i}][cognome]`)} />
                          </Col>
                          <Col>
                            <label>Età</label>
                            <input type="number" min="1" max="100" step="1" placeholder="Età" {...register(`p_clients[${i}][eta]`)} />
                          </Col>
                          <Col>
                            <a className='hint_link' onClick={() => fillClient('p', i)}><DynamicIcon iconName='FaHandPointUp' /> Usa dati titolare</a>
                          </Col>
                        </Row>
                      ))}
                    </div>
                    { !reservation ? 
                      <>
                        <hr />
                        <h4>Dati pagamento</h4>
                        <Row>
                          <Col>
                            <label>Stato pagamento</label>
                            <select {...register("reservation[pagamento_effettuato]", { required: true })}>
                              <option value="">- Scegli -</option>
                              <option value="1">Pagata</option>
                              <option value="0">Da pagare</option>
                            </select>
                          </Col>
                          <Col>
                            <label>Importo pagamento (€)</label>
                            <input type="number" step="1" {...register("reservation[pagamento_importo", { required: true })} />
                          </Col>
                          <Col>
                            <label>Metodo di pagamento</label>
                            <select {...register("reservation[pagamento_tipo]", { required: true })}>
                              <option value="">- Seleziona metodo pagamento -</option>
                              <option value="online">Online</option>
                              <option value="standby">Standby</option>
                              {/* <option value="contanti">Contanti</option> */}
                              <option value="pos">POS</option>
                              <option value="bonifico">Bonifico</option>
                            </select>
                          </Col>
                        </Row>
                      </>
                    : '' }
                    <hr />
                    <h4>Note</h4>
                    <Row>
                      <Col>
                        {/* <label>Note prenotazione</label> */}
                        {/* <textarea placeholder="Note o appunti" {...register("reservation[note]")}></textarea> */}
                        <Controller
                            render={({ field: { onChange, value } }) => (
                                <WYSIWYGEditor 
                                    onChange={onChange}
                                    value={value}
                                />
                            )}
                            name="reservation[note]"
                            control={control}
                            defaultValue="{}" // Default to an empty raw content object
                        />
                      </Col>
                    </Row>
                    <Button type="submit" variant="success" className="waste_btn">Salva prenotazione</Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </>
      : '' }
    </Container>
  );
  
}

export default AdminReservationsAdd;