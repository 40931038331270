import React, { useState, useEffect, useContext, useRef } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation, useParams, redirect } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
import FrontHeader from '../partials/header_front';
// CALENDAR
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';

function Calendar() {
  // AUTH, STATE, NAVIGATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // let params = useParams();
  const { voucherCode, escTypeOnly } = useParams();
  // CALENDAR
  const calendarRef = useRef(null);
  // DATA
  const [drivers, setDrivers] = useState(0);
  const [passengers, setPassengers] = useState(0);
  const [escTypeSelected, setEscTypeSelected] = useState(null);
  const [multiTypes, setMultiTypes] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [slots, setSlots] = useState([]);
  const [escTypes, setEscTypes] = useState([]);
  const [voucher, setVoucher] = useState(null);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    axios.post(window.SERVER_URL+'slot_types/list', {}, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(res => {
      console.log(res.data);
      setEscTypes(res.data);

      let tmp_esctype = escTypeSelected;

      // se ho passato sullo state il codice del voucher o se ho passato il parametro URL codificato, faccio il retrieve delle informazioni del buono
      if ((state && state.voucher) || voucherCode) {
        axios.post(window.SERVER_URL+'coupons/check', { codice: (state && state.voucher ? state.voucher : atob(voucherCode)) }, {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }).then(resk => {
          console.log(resk.data);
          if (resk.data.success) { // il codice è stato validato
            axios.post(window.SERVER_URL+'coupons/get', { codice: (state && state.voucher ? state.voucher : atob(voucherCode)) }, {
              headers: {
                'Access-Control-Allow-Origin': '*'
              }
            }).then(resc => {
              console.log(resc.data);
              setVoucher(resc.data);
              
              // devo filtrare i tipi escursioni per far vedere solo quelli compatibili col buono immesso
              if (resc.data.graphic.includes('|')) {  // se la stringa è composta quindi devo abilitare più tipologie di escursione
                let ar_types = resc.data.graphic.split('|');
                ar_types.forEach((item, i) => {
                  ar_types[i] = item.substr(item.indexOf("/")+1);
                });
                setEscTypes(res.data.filter((escType) => ar_types.includes(escType.codice)));
                tmp_esctype = ar_types.join('|');
                setMultiTypes(ar_types.join('|'));
                setEscTypeSelected(null);
              } else {  // se invece sto considerato una sola tipologia                
                setEscTypes(res.data.filter((escType) => escType.id == resc.data.tipo.id));
                tmp_esctype = resc.data.tipo.id;
                setEscTypeSelected(resc.data.tipo.id);
              }
            });
          } else {  // il codice non è valido
            Swal.fire({
              icon: 'error',
              title: 'Il buono regalo non è valido',
              html: `Il codice che stai cercando di utilizzare<br>
                    <b>${(state && state.voucher ? state.voucher : atob(voucherCode))}</b><br>
                    non appartiene a un buono regalo valido, è scaduto oppure è stato già utilizzato.
                    <br><br>
                    Controlla e riprova per favore.`
            });
          }
        });
      }

      // se ho passato il parametro URL che setta il tipo di escursione
      if (escTypeOnly) {  
        res.data.forEach((escType) => {
          if (escType.codice == atob(escTypeOnly)) {
            tmp_esctype = escType.id;
            setEscTypeSelected(escType.id);
          }
        });
      }

      setTimeout(function() {
        retrieveSlots(drivers, passengers, tmp_esctype);
      }, 1000);
    });
  }, [globalRefresh, calendarRef]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  const retrieveSlots = (num_drivers, num_passengers, type_id) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const startDate = calendarApi.view.currentStart;
      const weekStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`;

      axios.post(window.SERVER_URL+'slots/list', {
        num_guidatori: num_drivers, 
        num_passeggeri: num_passengers, 
        slot_type_id: (multiTypes && !type_id ? multiTypes : type_id), // se ho un set di tipologie predefinito e non ne ho scelta una in particolare, sono vincolato a quel set
        inizio_settimana: weekStartDate, 
        voucher: (state && state.voucher ? state.voucher : null) 
      }, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then(res => {
        console.log(res.data);
        
        if (res.data.length) {
          const fetchedEvents = res.data.map((event) => ({
            ...event,
            classNames: event.disponibile ? [] : ['disabled-event'],
          }));
          setSlots(fetchedEvents);
        } else {
          setSlots([]);
        }
      });
    }
  }

  const handleDatesSet = () => {
    retrieveSlots(drivers, passengers, escTypeSelected);
  }

  const handleDateClick = (info) => {}

  const handleEventClick = (info) => {
    if (info.event.classNames.includes('disabled-event')) {
      info.jsEvent.preventDefault();
      alert('Questo evento non è selezionabile');
    } else {
      const check_date_time = `${info.event.start.getFullYear()}-${String(info.event.start.getMonth() + 1).padStart(2, '0')}-${String(info.event.start.getDate()).padStart(2, '0')} ${String(info.event.start.getHours()).padStart(2, '0')}:${String(info.event.start.getMinutes()).padStart(2, '0')}:00`;
      if (voucher && voucher.expire_at < check_date_time) {  // se sto usando un buono ma ho selezionato un'escursione il cui inizio è oltre la validità del buono
        Swal.fire({
          icon: 'warning',
          title: 'Attenzione',
          html: `Utilizzando questo buono non puoi prenotare l'escursione selezionata perché è oltre il limite di validità del buono.<br><br>
                Scadenza buono regalo: <b>${voucher.expire_at.substr(0,10).split("-").reverse().join("/")} ${voucher.expire_at.substr(11,5)}</b><br>
                Data escursione selezionata: <b>${check_date_time.substr(0,10).split("-").reverse().join("/")} ${check_date_time.substr(11,5)}</b>`,
          confirmButtonColor: "#314491",
          confirmButtonText: 'Ok',
        });
      } else {  // nessun buono oppure buono utilizzabile
        let ar_check = checkFilters(info.event.extendedProps.slot_type_id);
        let check = ar_check[0];
        let message = ar_check[1];
        if (check) {
          const startDate = info.event.start;
          const formattedDate = `${String(startDate.getDate()).padStart(2, '0')}/${String(startDate.getMonth() + 1).padStart(2, '0')}/${startDate.getFullYear()}`;    
          const formattedTime = `${String(startDate.getHours()).padStart(2, '0')}:${String(startDate.getMinutes()).padStart(2, '0')}`;
          
          Swal.fire({
            html: `<img class="logo" src="https://escursionimotoslitte.it/img/escursioni-motoslitte-logo.svg"><br>
                    <p><b>Stai per prenotare l'escursione</b><br /><span style="color: #314491">${info.event.title}</span></p>
                    <p><b>Data:</b> <span style="color: #314491">${formattedDate}</span> - <b>Orario:</b> <span style="color: #314491">${formattedTime}</span></p>
                    <p><b>Vuoi procedere con la prenotazione?</p>`,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Conferma',
            denyButtonText: 'Annulla',
            confirmButtonColor: "#314491",
            cancelButtonColor: "#cb4f2c",
            width: '600px',
          }).then((result) => {
            if (result.isConfirmed) {
              axios.post(window.SERVER_URL+'reservations/save', { slot_id: info.event.id, num_guidatori: drivers, num_passeggeri: passengers, voucher: (voucher ? voucher.code : null) }, {
                headers: {
                  'Access-Control-Allow-Origin': '*'
                }
              }).then(res => {
                console.log(res.data);
                if (res.data.success && res.data.id)
                  navigate('/book', { state: { num_drivers: drivers, num_passengers: passengers, reservation_id: res.data.id } });
                else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Prenotazione fallita',
                    text: "Si è verificato un errore, per favore ricomincia la prenotazione",
                    confirmButtonColor: "#314491",
                    confirmButtonText: "Torna all'inizio"
                  }).then((result) => {
                    navigate('/');
                  });
                }
              });
            }
          });
        } else {
          Swal.fire({
            icon: 'warning',
            title: 'Attenzione',
            html: message,
            confirmButtonColor: "#314491",
            confirmButtonText: 'Ok, correggo',
          });
        }
      }
    }
  }

  const checkFilters = (type_id) => {
    let check = true;
    let message = null;
    // Sul trenino MAX 5 passeggeri
    if (type_id == 4 && parseInt(passengers) > 5) {
      console.log(type_id, drivers, passengers);
      check = false;
      message = "Sul trenino possono salire solo <b>massimo 5 passeggeri</b>";
    }
    // Nell'escursione del trenino NO guidatori
    if (type_id == 4 && parseInt(drivers) > 0) {
      console.log(type_id, drivers, passengers);
      check = false;
      message = "Nell'escursione sul trenino <b>non possono</b> esserci guidatori";
    }
    // Sulle escursion standard MAX 5 guidatori e MAX 6 passeggeri
    if (type_id != 4 && (parseInt(drivers) > 5 || parseInt(passengers) > 6)) {
      console.log(type_id, drivers, passengers);
      check = false;
      message = "Nelle escursioni (tranne il trenino) possono esserci <b>max 5 guidatori</b> e <b>max 6 passeggeri</b>";
    }
    // Sulle escursion standard devono esserci almeno 1 guidatore e massimo ("n guidatori"+1) passeggeri
    if ((type_id != 4) && (parseInt(drivers) == 0 || parseInt(passengers) > (parseInt(drivers)+1))) {
      console.log(type_id, drivers, passengers);
      check = false;
      message = "Nelle escursioni (tranne il trenino) deve esserci <b>almeno 1 guidatore</b> e può esserci solo <b>1 passeggero in più</b> del num. totale di guidatori";
    }
    // Bisogna selezionare il tipo di escursione
    /*
    if (!type_id) {
      console.log(type_id, drivers, passengers);
      check = false;
      message = "Devi selezionare la <b>tipologia</b> di escursione";
    }
    */
    // Bisogna selezionare almeno 1 tra guidatori e passeggeri
    if (parseInt(drivers) == 0 && parseInt(passengers) == 0) {
      console.log(type_id, drivers, passengers);
      check = false;
      message = "Devi selezionare <b>almeno un partecipante</b> per l'escursione";
    }

    return [check, message];
  }

  const handleTypeChange = (type_id) => {
    console.log(type_id);
    setEscTypeSelected(type_id);
    retrieveSlots(drivers, passengers, type_id);
  }

  const handleDriversChange = (num_drivers) => {
    console.log(num_drivers);
    setDrivers(num_drivers);
    retrieveSlots(num_drivers, passengers, escTypeSelected);
  }
  
  const handlePassengersChange = (num_passengers) => {
    console.log(num_passengers);
    setPassengers(num_passengers);
    retrieveSlots(drivers, num_passengers, escTypeSelected);
  }
  
  return (
    <Container className="front_content">
      <FrontHeader />
      <Row id='subheader'>
        <Col>
          <h2>Componi la tua escursione</h2>
        </Col>
      </Row>
      <Row className='panel'>
        <Col>
          <label>Num. guidatori</label><br />
          <select onChange={(e) => handleDriversChange(e.target.value)}>
            {Array.from({ length: 6 }, (_, i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </select>
        </Col>
        <Col>
          <label>Num. passeggeri</label><br />
          <select onChange={(e) => handlePassengersChange(e.target.value)}>
            {Array.from({ length: 7 }, (_, i) => (
              <option key={i} value={i}>
                {i}
              </option>
            ))}
          </select>
        </Col>
        <Col>
          <label>Tipo escursione</label><br />
          <select onChange={(e) => handleTypeChange(e.target.value)} value={escTypeSelected}>
            { !voucher || voucher.graphic.includes('|') ?
              <option value={multiTypes ? multiTypes : ''}>- Qualunque tipologia -</option>
            : '' }
            { escTypes && escTypes.length ? escTypes.map((type) => 
              <option key={type.id} value={type.id}>{ type.descrizione }</option>
            ) : '' }
          </select>
        </Col>
        <Col>
          { voucher ?
            <>
              <p style={{'margin':'0'}}>
                <label>Buono regalo: &nbsp;</label> 
                { voucher ? voucher.code : '-' }
                {/* +' [-'+voucher.amount+(voucher.unit == 'euro' ? '€' : '%')+']' */}
              </p>
              <p style={{'margin':'0'}}>
                <label>Valido fino al: &nbsp;</label>
                { voucher.expire_at.substr(0,10).split("-").reverse().join("/") } { voucher.expire_at.substr(11,5) }
              </p>
            </>
          :
            <p className='alert'>Hai un buono regalo da utilizzare? <a href="/voucher"><b>Clicca qui!</b></a></p>
          }
        </Col>
      </Row>
      <Row>
        <Col id='calendar_wrapper' style={{'marginTop':'25px'}}>
          <FullCalendar 
            ref={calendarRef}
            plugins={[ timeGridPlugin, interactionPlugin ]} 
            locale={itLocale} 
            initialView={isMobile ? 'timeGridDay' : 'timeGridWeek'}
            initialDate={new Date() < new Date('2024-12-08') ? '2024-12-08' : new Date()}
            eventClick={handleEventClick} 
            datesSet={handleDatesSet}
            events={slots} 
            slotMinTime="08:00:00" 
            slotMaxTime="20:00:00" 
            allDaySlot={false} 
            slotLabelFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }} 
            height='auto'
          />
        </Col>
      </Row>
    </Container>
  );

}

export default Calendar;