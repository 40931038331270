import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation, redirect } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm, useFieldArray } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
import FrontHeader from '../partials/header_front';

function Book() {
  // AUTH, STATE, NAVIGATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  // CUSTOM HOOKS
  const { calcAmount, handlePayment } = useHelpers();
  // FORM
  const { register, control, handleSubmit, trigger, getValues, setValue, reset, formState: { errors } } = useForm();
  // DATA
  const [reservation, setReservation] = useState(null);
  const [amount, setAmount] = useState(null);
  const [amountToPay, setAmountToPay] = useState(null);

  useEffect(() => {
    if (state && state.reservation_id) {
      axios.post(window.SERVER_URL+'reservations/get', { id: state.reservation_id }, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then(res => {
        console.log(res.data);
        setReservation(res.data);
        setValue('reservation[id]', res.data.id);
        // calcolo il totale da pagare della prenotazione
        let amounts = calcAmount(res.data);
        setAmount(amounts[0]); // il prezzo standard
        setAmountToPay(amounts[1]); // il prezzo scontato
        setValue("reservation[pagamento_importo]", amounts[1]); // setto il campo hidden cosi viene salvato l'importo nel record della prenotazione
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Prenotazione fallita',
        text: "Si è verificato un errore, per favore ricomincia la prenotazione",
        confirmButtonColor: "#314491",
        confirmButtonText: "Torna all'inizio"
      }).then((result) => {
        navigate('/');
      });
    }
  }, [globalRefresh]);

  const fillClient = (i) => {
    console.log(getValues());
    console.log(i);
    setValue(`clients[${i}][nome]`, getValues('reservation[titolare_nome]'));
    setValue(`clients[${i}][cognome]`, getValues('reservation[titolare_cognome]'));
    setValue(`clients[${i}][eta]`, getValues('reservation[titolare_eta]'));
  }

  const onSubmit = (form_data) => {
    console.log(form_data);
    axios.post(window.SERVER_URL+'reservations/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(res => {
      console.log(res.data);
      if (res.data.success) {
        handlePayment(form_data.reservation.id, amountToPay);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Si è verificato un errore',
          html: "La tua prenotazione non è stata salvata<br />Riprova per favore",
          confirmButtonColor: "#314491",
          confirmButtonText: "Ok"
        });
      }
    });
  }
  
  return (
    <Container className="front_content">
      <FrontHeader />
      <Row id='subheader'>
        <Col>
          <h2>Completa la tua prenotazione</h2>
        </Col>
      </Row>
      <Row className='panel first'>
        <Col>
          <p style={{'margin':'0'}}><label>Tipologia escursione:</label> { reservation ? reservation.slot.slot_type.descrizione : '-' }</p>
          <p><label>Data e ora:</label> { reservation ? reservation.slot.inizio.substr(0, 10).split("-").reverse().join("/")+' '+reservation.slot.inizio.substr(11, 5) : '-' }</p>
        </Col>
        <Col>
          <p style={{'margin':'0'}}><label>Num. guidatori:</label> { state && state.num_drivers ? state.num_drivers : '0' }</p>
          <p><label>Num. passeggeri:</label> { state && state.num_passengers ? state.num_passengers : '0' }</p>
        </Col>
        <Col>
          { reservation && reservation.voucher && reservation.coupon ?
            <>
              <p style={{'margin':'0'}}>
                <label>Buono regalo: &nbsp;</label> 
                { reservation && reservation.voucher && reservation.coupon ? reservation.voucher : '-' }
                {/* +' [-'+reservation.coupon.amount+(reservation.coupon.unit == 'euro' ? '€' : '%')+']' */}
              </p>
              <p>
                <label>Valido fino al: &nbsp;</label>
                { reservation.coupon.expire_at.substr(0,10).split("-").reverse().join("/") } { reservation.coupon.expire_at.substr(11,5) }
              </p>
            </>
          :
            <p className='alert'>Hai un buono regalo da utilizzare? <a href="/voucher"><b>Clicca qui!</b></a></p>
          }
        </Col>
      </Row>
      <br />
      <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="id" {...register("reservation[id]")} value={ reservation ? reservation.id : null } />
        <Row className='panel'>
          <Col className="custom_form_wrapper">
            <h4>Dati titolare prenotazione</h4>
            <Row>
              <Col>
                <label>Nome</label>
                <input type="text" placeholder="Nome" {...register("reservation[titolare_nome]", { required: true })} />
              </Col>
              <Col>
                <label>Cognome</label>
                <input type="text" placeholder="Cognome" {...register("reservation[titolare_cognome]", { required: true })} />
              </Col>
              <Col>
                <label>Cellulare</label>
                <input type="text" placeholder="Cellulare" {...register("reservation[titolare_telefono]", { required: true })} />
              </Col>
            </Row>
            <Row style={{'marginTop':'10px'}}>
              <Col>
                <label>E-mail</label>
                <input type="email" placeholder="E-mail" {...register("reservation[titolare_email]", { required: true })} />
              </Col>
              <Col lg={2}>
                <label>Età</label>
                <input type="number" min="18" max="100" step="1" placeholder="Età" {...register("reservation[titolare_eta]", { required: true })} />
              </Col>
              <Col lg={2}>
                <label>Genere</label>
                <select {...register("reservation[titolare_genere]", { required: true })}>
                  <option value="">-</option>
                  <option value="M">Uomo</option>
                  <option value="F">Donna</option>
                  <option value="X">Preferisco non specificare</option>
                </select>
              </Col>
              <Col>
                <label>Come ci hai trovato?</label>
                <select {...register("reservation[marketing_info]", { required: true })}>
                  <option value="">-</option>
                  <option value="Volantini">Volantini</option>
                  <option value="Già cliente">Già cliente</option>
                  <option value="Internet">Internet</option>
                  <option value="Passaparola">Passaparola</option>
                  <option value="Fiera o evento">Fiera/evento</option>
                </select>
              </Col>
            </Row>
            <hr />
            <h4>Dati partecipanti</h4>
            <div id='persons_wrapper'>
              {/* GUIDATORI */}
              { state && state.num_drivers ? Array.from({ length: state.num_drivers }, (_, i) => (
                <Row className='person_row'>
                  <input type="hidden" value="1" {...register(`clients[${i}][client_type_id]`, { required: true })} />
                  <p><DynamicIcon iconName='GiSteeringWheel' size="24" /> GUIDATORE {i+1}</p>
                  <Col>
                    <label style={{'marginRight':'20px'}}>Nome</label>
                    <input type="text" placeholder="Nome" {...register(`clients[${i}][nome]`, { required: true })} />
                  </Col>
                  <Col>
                    <label>Cognome</label>
                    <input type="text" placeholder="Cognome" {...register(`clients[${i}][cognome]`, { required: true })} />
                  </Col>
                  <Col>
                    <label>Età</label>
                    <input type="number" min="1" max="100" step="1" placeholder="Età" {...register(`clients[${i}][eta]`, { required: true })} />
                  </Col>
                  <Col>
                    <a className='hint_link' onClick={() => fillClient(i)}><DynamicIcon iconName='FaHandPointUp' /> Usa dati titolare</a>
                  </Col>
                </Row>
              )) : '' }
              {/* PASSEGGERI */}
              { state && state.num_passengers ? Array.from({ length: state.num_passengers }, (_, i) => (
                <Row className='person_row'>
                  <input type="hidden" value="2" {...register(`clients[${i+parseInt(state.num_drivers)}][client_type_id]`, { required: true })} />
                  <p><DynamicIcon iconName='MdEventSeat' size="24" /> PASSEGGERO {i+1}</p>
                  <Col>
                    <label style={{'marginRight':'20px'}}>Nome</label>
                    <input type="text" placeholder="Nome" {...register(`clients[${i+parseInt(state.num_drivers)}][nome]`, { required: true })} />
                  </Col>
                  <Col>
                    <label>Cognome</label>
                    <input type="text" placeholder="Cognome" {...register(`clients[${i+parseInt(state.num_drivers)}][cognome]`, { required: true })} />
                  </Col>
                  <Col>
                    <label>Età</label>
                    <input type="number" min="1" max="100" step="1" placeholder="Età" {...register(`clients[${i+parseInt(state.num_drivers)}][eta]`, { required: true })} />
                  </Col>
                  <Col>
                    <a className='hint_link' onClick={() => fillClient(i+parseInt(state.num_drivers))}><DynamicIcon iconName='FaHandPointUp' /> Usa dati titolare</a>
                  </Col>
                </Row>
              )) : '' }
            </div>
          </Col>
        </Row>
        <br />
        <Row className='panel'>
          <Col>
            <label>Totale prenotazione</label>
            { amountToPay <= 0 ?
              <p id="amount">GRATIS</p>
            : 
              <p id="amount">{ amount != amountToPay ? amountToPay+'€ invece di '+amount : amountToPay } €</p>
            }
            <input type="hidden" {...register("reservation[pagamento_importo]", { required: true })} />
          </Col>
          <Col style={{'textAlign':'right','position':'relative'}}>
            <div id="agreement_box">
              <input type="checkbox" {...register("reservation[privacy]", { required: true })} /> Accetto i <a href={window.TERMS_URL} target='_blank'>termini e condizioni</a> e la <a href={window.PRIVACY_URL} target='_blank'>privacy policy</a>
            </div>
            <Button type="submit" variant="success" className="waste_btn" id="pay_btn">
              { amountToPay <= 0 ? 'Conferma prenotazione' : 'Salva e procedi al pagamento' }
            </Button>
          </Col>
        </Row>
      </form>
    </Container>
  );
}

export default Book;