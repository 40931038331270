import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { Link, useNavigate } from "react-router-dom";
// AXIOS
import axios from 'axios';
// CONFIG
// import conf from "../../config.json";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// TOLTIP
import { Tooltip } from 'react-tooltip'
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function toggleMenu() {
  document.getElementById('menu').classList.toggle('active');
}

function closeMenu() {
  document.getElementById('menu').classList.remove('active');
}

function openSubNav(e) {
  e.preventDefault();
  let submenu = e.target.parentNode.querySelector('ul');
  if (submenu)
    submenu.classList.toggle('visible');
  return false;
}

function Header() {
  // AUTH, STATE, NAVIGATION
  const { authobj, lingue, lang, setLanguage, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  let navigate = useNavigate();
  // DATA
  const [numAlertsUnread, setNumAlertsUnread] = useState(0);
  const italianMonths = ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"];

  useEffect(() => {
    axios.post(window.SERVER_URL+'vouchers/waiting', {}, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setNumAlertsUnread(res.data);
    });

    closeMenu();
    setGlobalRefresh(null);
  }, [globalRefresh]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      let query = event.target.value;
      navigate('/admin/reservations', { state: { page: 1, query: query } })
      setGlobalRefresh(1);
    }
  };


  return (
    <>
      <Container id='header'>
        <Row>
          <Col>
            <span style={{'display':'inline-block','paddingTop':'12px'}}>
              <img src={`${process.env.PUBLIC_URL}/gfx/Iconly-Light-Paper.png`} style={{'display':'inline-block','marginRight':'10px'}} />
              {`${(new Date()).getDate()} ${italianMonths[(new Date()).getMonth()]} ${(new Date()).getFullYear()}`}
            </span>
          </Col>
          <Col id='headicons_wrapper'>
            <div className='headicon' onClick={toggleMenu}><DynamicIcon iconName='GiHamburgerMenu' /></div>
            <div className='headicon' id="dashboard_speed_link" onClick={() => { navigate('/admin/dashboard')}}><DynamicIcon iconName='MdDashboard' /></div>
            <div className='headicon' onClick={() => { navigate('/admin/vouchers') }}>
              <img src={`${process.env.PUBLIC_URL}/gfx/Raggruppa 163.png`} className='alerts_header_btn' />
              <span>{ numAlertsUnread }</span>
              <Tooltip anchorSelect=".alerts_header_btn" place="bottom" variant="info">Ci sono <b>{ numAlertsUnread }</b> voucher da validare</Tooltip>
            </div>
            <div id="search_wrapper">
              <input type="text" name="search" placeholder="Cerca... (scrivi e premi Invio)" onKeyDown={handleKeyDown} />
            </div>
          </Col>
        </Row>
      </Container>
      <Container id='menu'>
        <Row>
          <div className="sx" onClick={toggleMenu}>
            <DynamicIcon iconName='GiHamburgerMenu' />
          </div>
          <div className="dx">
            <img alt="SPF" id='logo' src='https://escursionimotoslitte.it/img/escursioni-motoslitte-logo.svg' style={{'paddingLeft':'40px'}} onClick={() => { setGlobalRefresh(1); navigate('/admin/dashboard') }} />
            
            <div id="nav_wrapper">
              <nav>
                <ul>
                  <li><Link to="/admin/dashboard" onClick={closeMenu}><DynamicIcon iconName='MdDashboard' /> Dashboard</Link></li>
                  <li><Link to="#" onClick={openSubNav}><DynamicIcon iconName='FaCalendarAlt' /> Escursioni <DynamicIcon iconName='IoMdArrowDropdown' className="last" /></Link>
                    <ul>
                      <li><Link to="/admin/slots/add" onClick={closeMenu}><DynamicIcon iconName='FaCalendarDay' /> Aggiungi escursione</Link></li>
                      <li><Link to="/admin/slots/generate" onClick={closeMenu}><DynamicIcon iconName='BiSolidDuplicate' /> Duplica giornata</Link></li>
                      <li><Link to="/admin/slot_types" onClick={closeMenu}><DynamicIcon iconName='FaListUl' /> Tipi escursione</Link></li>
                    </ul>
                  </li>
                  <li><Link to="#" onClick={openSubNav}><DynamicIcon iconName='ImUsers' /> Prenotazioni <DynamicIcon iconName='IoMdArrowDropdown' className="last" /></Link>
                    <ul>
                      <li><Link to="/admin/reservations" onClick={closeMenu}><DynamicIcon iconName='ImUsers' /> Gestisci prenotazioni</Link></li>
                      <li><Link to="/admin/reservations/trash" onClick={closeMenu}><DynamicIcon iconName='FaTrash' /> Cestino annullate</Link></li>
                    </ul>
                  </li>
                  <li><Link to="/admin/vouchers" onClick={closeMenu}><DynamicIcon iconName='FaTicketAlt' /> Buoni partner</Link></li>
                  <li><Link to={window.HOLIPAY_URL} target="_blank" onClick={closeMenu}><DynamicIcon iconName='FaExternalLinkAlt' /> Buoni Holitaly</Link></li>
                  <li><Link to="/admin/paylinks/add" onClick={closeMenu}><DynamicIcon iconName='BsFillCreditCardFill' /> Link pagamento</Link></li>
                  {/* <li><Link to="#" onClick={openSubNav}><img src={`${process.env.PUBLIC_URL}/gfx/SPF_AssetsIcone-03.png`} /> Utenti, ruoli e permessi <DynamicIcon iconName='IoMdArrowDropdown' className="last" /></Link>
                    <ul>
                      <li><Link to="/users" onClick={closeMenu}><DynamicIcon iconName='ImUsers' /> Gestione utenti</Link></li>
                      <li><Link to="/roles" onClick={closeMenu}><DynamicIcon iconName='FaUnlockAlt' /> Ruoli e permessi</Link></li>
                    </ul>
                  </li> */}
                </ul>
              </nav>

              <hr />
              <div id="info_connected" className='profile_link' style={{'position':'relative'}}>
                <div onClick={() => { if (authobj.user.role.slug == 'tec') navigate('/technicians/add', { state: { technician_id: authobj.user.id } }); } }>
                  <DynamicIcon iconName='FaUserCircle' id="user_icon" />
                  <p className='role_label sidebar'>{ authobj.role ? authobj.role : 'Administrator' }</p>
                  <div style={{'textAlign':'center'}}>
                    <b>{ authobj.name }</b>
                    <p style={{'marginTop':'5px'}}>{ authobj.email }</p>
                  </div>
                </div>
                <Link to="#" className="logout" onClick={signout}><DynamicIcon iconName='ImExit' /> Logout</Link>
              </div>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Header;