import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation, useParams, redirect } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// CUSTOM HOOKS
import { useHelpers } from '../../hooks/useHelpers';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// CALENDAR
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminDashboard() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  const { initDate } = useParams();
  // CUSTOM HOOKS
  const { getOccupationClass } = useHelpers();
  // CALENDAR
  const calendarRef = useRef(null);
  // DATA
  const [drivers, setDrivers] = useState(0);
  const [passengers, setPassengers] = useState(0);
  const [escTypeSelected, setEscTypeSelected] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    retrieveSlots(drivers, passengers, escTypeSelected);
  }, [globalRefresh, calendarRef]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 768);
  };

  const retrieveSlots = (num_drivers, num_passengers, type_id) => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const startDate = calendarApi.view.currentStart;
      const weekStartDate = `${startDate.getFullYear()}-${String(startDate.getMonth() + 1).padStart(2, '0')}-${String(startDate.getDate()).padStart(2, '0')}`;

      axios.post(window.SERVER_URL+'slots/list', {
        num_guidatori: num_drivers, 
        num_passeggeri: num_passengers, 
        slot_type_id: type_id, 
        inizio_settimana: weekStartDate, 
        voucher: (state && state.voucher ? state.voucher : null) 
      }, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }
      }).then(res => {
        console.log(res.data);
        
        if (res.data.length) {
          const fetchedEvents = res.data.map((event, i) => ({
            ...event,
            // title: res.data[i].title+' ['+event.utenti.occupazione+'%]',
            classNames: event.disponibile ? [getOccupationClass(event.utenti.occupazione)] : ['disabled-event', getOccupationClass(event.utenti.occupazione)],
            utenti: event.utenti
          }));
          setSlots(fetchedEvents);
        } else {
          setSlots([]);
        }
      });
    }
  }

  const eventRenderer = (arg) => {
    // Create the title element
    let titleEl = document.createElement('div');
    titleEl.innerText = arg.event.title;
  
    // Create the percentage element with a CSS class
    let percentageEl = document.createElement('div');
    percentageEl.innerText = `${arg.event.extendedProps.utenti.occupazione}%`;
    percentageEl.classList.add('event-percentage');  // Add the CSS class
  
    // Append both elements to the event container
    let containerEl = document.createElement('div');
    containerEl.classList.add('event-container');  // Optional: Add a container class for easier styling
    containerEl.appendChild(titleEl);
    containerEl.appendChild(percentageEl);
  
    return { domNodes: [containerEl] };
  };

  const handleDatesSet = () => {
    retrieveSlots(drivers, passengers, escTypeSelected);
  }

  const handleDateClick = (info) => {}

  const handleEventClick = (info) => {
    navigate('/admin/slots/view', { state: { slot_id: info.event.id } });
  }
  
  return (
    <Container className="admin content">
      <Row className="page_title">
        <Col>
          {/* <h1>{t('ADMIN_DASHBOARD_TITOLO')}</h1> */}
        </Col>
      </Row>
      <Row>
        <Col>
          <Button variant="success" style={{margin:'0 10px'}} onClick={() => { navigate('/admin/slots/add'); }}><DynamicIcon iconName='FaCalendarDay' size="20" spaced /> Aggiungi escursione</Button>
        </Col>
      </Row>
      <Row>
        <Col id='calendar_wrapper' style={{'marginTop':'25px'}}>
          <FullCalendar 
            ref={calendarRef}
            plugins={[ timeGridPlugin, interactionPlugin ]} 
            locale={itLocale} 
            initialView={isMobile ? 'timeGridDay' : 'timeGridWeek'}
            initialDate={initDate ? initDate : new Date()}
            eventClick={handleEventClick} 
            datesSet={handleDatesSet}
            events={slots}
            eventContent={eventRenderer}
            slotMinTime="08:00:00" 
            slotMaxTime="24:00:00" 
            allDaySlot={false} 
            slotLabelFormat={{ hour: 'numeric', minute: '2-digit', hour12: false }} 
            height='auto'
          />
        </Col>
      </Row>
    </Container>
  );
  
}

export default AdminDashboard;