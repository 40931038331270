import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm, useFieldArray } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// CALENDAR
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import multiMonthPlugin from '@fullcalendar/multimonth'
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminSlotsGenerate() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  // CALENDAR
  const calendarRef = useRef(null);
  // FORM
  const { register, control, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm();
  // DATA
  const [dates, setDates] = useState([]);

  useEffect(() => {
    
  }, []);

  const handleDateClick = (arg) => {
    const clickedDate = arg.dateStr;
    if (dates.includes(clickedDate)) {
      setDates(dates.filter(date => date !== clickedDate));
      arg.dayEl.classList.remove('clicked-date');
    } else {
      setDates([...dates, clickedDate]);
      arg.dayEl.classList.add('clicked-date');
    }
  };

  const onSubmit = (form_data) => {
    form_data.date = dates;
    console.log(form_data);
    if (!dates.length) {
      Swal.fire({
        icon: 'error',
        title: 'Errore',
        text: "Devi scegliere almeno una data in cui duplicare"
      });
    } else {
      axios.post(window.SERVER_URL+'slots/generate', form_data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }
      }).then(res => {
        console.log(res.data);
        if (res.data.success) {
          Swal.fire({
            icon: 'success',
            title: 'Giornata duplicata',
            html: "La giornata è stata duplicata"
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Si è verificato un errore',
            html: "La giornata non è stata duplicata<br />Riprova per favore",
            confirmButtonColor: "#314491",
            confirmButtonText: "Ok"
          });
        }
      });
    }
  }
  
  return (
    <Container className="content admin">
      <Row className="page_title">
        <Col>
          <h1>Duplica giornata</h1>
        </Col>
      </Row>
      <Row>
        <Col className='tab_wrapper'>
          <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
            <Row className='panel'>
              <Col className="custom_form_wrapper">
                <Row>
                  <Col>
                    <label>Data da duplicare</label>
                    <input type="date" {...register("origine", { required: true })} />
                  </Col>
                </Row>
                <Row style={{'marginTop':'20px'}}>
                  <Col>
                    <h4>Date selezionate</h4>
                    <p style={{'textAlign':'center','marginTop':'-15px','fontSize':'14px'}}>
                      <DynamicIcon iconName='FaInfoCircle' spaced />
                      <i>Clicca di nuovo su una data nel calendario per deselezionarla</i>
                    </p>
                    { dates && dates.length ?
                      <ul id="selected_dates">
                        {dates.map((date, index) => (
                          <li key={index}>{date.split("-").reverse().join("/")}</li>
                        ))}
                      </ul>
                    : <p className='alert'>Non hai ancora selezionato date in cui duplicare</p> }
                    <hr />
                    <h4>Scegli le date in cui duplicare</h4>
                    <FullCalendar
                      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, multiMonthPlugin]}
                      initialView="multiMonthYear"
                      locale={itLocale} 
                      dateClick={handleDateClick}
                    />
                  </Col>
                </Row>
                <Button type="submit" variant="success" className="waste_btn">Duplica giornata</Button>
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Container>
  );
  
}

export default AdminSlotsGenerate;