import React, { useContext, useState } from 'react';

export const GlobContext = React.createContext(null);

export const GlobProvider = ({ children }) => {
  let [wait, setWait] = useState(null);
  let [waitPercent, setWaitPercent] = useState(null);

  let value = { wait, setWait, waitPercent, setWaitPercent };

  return <GlobContext.Provider value={value}>{children}</GlobContext.Provider>;
}