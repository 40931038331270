import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
import FrontHeader from '../partials/header_front';

function Init() {
  // AUTH, STATE, NAVIGATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  let navigate = useNavigate();

  useEffect(() => {
    
  }, [globalRefresh]);
  
  return (
    <Container className="front_content">
      <FrontHeader />
      <Row>
        <Col>
          <div className='init_box' onClick={() => { navigate('/calendar') }}>
            Voglio effettuare<br />una prenotazione
          </div>
        </Col>
        <Col>
          <div className='init_box' onClick={() => { navigate('/voucher') }}>
            Voglio prenotare con<br />un buono regalo
          </div>
        </Col>
      </Row>
    </Container>
  );

}

export default Init;