import React, { useContext, useEffect, useState } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { Link, useNavigate } from "react-router-dom";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';

function FrontHeader() {
    // AUTH, STATE, NAVIGATION
    const { authobj, lingue, lang, setLanguage, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
    let navigate = useNavigate();
  
    useEffect(() => {
      
    }, [globalRefresh]);
  
    return (
        <>
            <Row className="page_title">
                <Col style={{'textAlign':'left'}}>
                    <img id='logo' src='https://escursionimotoslitte.it/img/escursioni-motoslitte-logo.svg' onClick={() => { navigate('/') }} />
                </Col>
                <Col>
                    <h1>Booking online</h1>
                </Col>
            </Row>
        </>
    )
}

export default FrontHeader;