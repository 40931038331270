import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
import FrontHeader from '../partials/header_front';

function Thank() {
  // AUTH, STATE, NAVIGATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  let navigate = useNavigate();

  useEffect(() => {
    
  }, [globalRefresh]);
  
  return (
    <Container className="front_content">
      <FrontHeader />
      <Row>
        <Col id="thank_wrapper" style={{'textAlign':'center','paddingTop':'100px'}}>
          <h1 style={{'color':'#314490','marginBottom':'40px'}}>Grazie per il tuo acquisto!</h1>
          <h4 style={{'color':'#CA4F2B'}}>La tua prenotazione è confermata<br />Ti è stata inviata un'e-mail con il riepilogo dei tuoi dati</h4>
          <h4>Ti aspettiamo il giorno dell'escursione<br />per farti vivere un'esperienza indimenticabile</h4>

          <Row style={{'paddingTop':'40px'}}>
            <Col>
              <Button onClick={() => { navigate('/'); }} style={{'marginRight':'40px'}}>Effettua una<br />nuova prenotazione</Button>
              <Button onClick={() => { window.location.href=`${window.SITE_URL}`; }}>Vai al nostro<br />sito web</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );

}

export default Thank;