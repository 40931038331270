import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// TOLTIP
import { Tooltip } from 'react-tooltip';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminVouchers() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  // PAGINAZIONE
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState(null);
  // DATA
  const [vouchers, setVouchers] = useState(null);
  // SWEETALERT
  const MySwal = withReactContent(Swal);

  useEffect(() => {
    getPage(
      state && state.page ? state.page : page,
      state && state.query ? state.query : query
    );
  }, [globalRefresh]);

  const getPage = (i, query) => {
    let params = {
      page: i
    }
    if (query && query.length >= 3)
      params.search = query;
      
    axios.post(window.SERVER_URL+'vouchers/list', { params }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setVouchers(res.data);
      setPage(i);
      setNumPages(res.data.pages);
      setCount(res.data.count);
      setQuery(query);
    });
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    getPage(1, typed);
  }

  const handleVoucherDelete = (voucher_id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Sei sicuro di voler annullare questa richiesta?',
      html: "La cancellazione è un'operazione irreversibile, presta attenzione.",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Conferma',
      denyButtonText: 'Ci ho ripensato',
      cancelButtonColor: "#314491", 
      confirmButtonColor: "#314491",
      // width: '600',
      }).then((result) => {
        if (result.isConfirmed) {
          axios.post(window.SERVER_URL+'vouchers/delete', { id: voucher_id }, { headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer '+authobj.token
          }}).then(res => {
            console.log(res.data);
            if (res.data.success) {
              Swal.fire({
                icon: 'success',
                title: 'Richiesta cancellata',
                html: "La richiesta è stata correttamente eliminata"
              }).then((res) => {
                setGlobalRefresh(1);
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Si è verificato un errore',
                html: "Non è stato possibile cancellare la richiesta, riprova per favore"
              });
            }
          });
        }
      });
  }
  
  return (
    <Container className="content admin">
      <Row className="page_title">
        <Col>
          <h1>Buoni partner</h1>
        </Col>
      </Row>
      <Row>
        <Col className='tab_wrapper'>
          { vouchers ? 
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th style={{'width':'50px'}}>Stato</th>
                    <th>ID</th>
                    <th>Data richiesta</th>
                    <th>Nome e cognome</th>
                    <th>Telefono</th>
                    <th>E-mail</th>
                    <th>Piattaforma</th>
                    <th>Codice</th>
                    <th style={{'width':'100px'}}>Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  { vouchers.length ? vouchers.map((voucher) => {
                      return <tr key={ voucher.id }>
                              <td><span className={ voucher.validato == 1 ? 'tag approved' : ' tag suspended' }></span></td>
                              <td>{ voucher.id }</td>
                              <td>{ voucher.created_at ? voucher.created_at.substr(0, 10).split("-").reverse().join("/") : '-' }</td>
                              <td>{ voucher.nome }</td>
                              <td>{ voucher.telefono }</td>
                              <td>{ voucher.email }</td>
                              <td>{ voucher.voucher_partner ? voucher.voucher_partner.nome : '-' }</td>
                              <td>{ voucher.codice.toUpperCase() }</td>
                              <td className="actions">
                                <Button className='voucher_det' onClick={() => { navigate('/admin/vouchers/view', { state: { voucher_id: voucher.id, page: page, query: query } }) }}>
                                  <DynamicIcon iconName='IoIosArrowForward' />
                                  <Tooltip anchorSelect=".voucher_det" place="top" variant="info">Vedi voucher</Tooltip>
                                </Button>
                                <Button onClick={() => { handleVoucherDelete(voucher.id) }} className="delete">
                                  <DynamicIcon iconName='FaTrash' />
                                  <Tooltip anchorSelect=".delete" place="top" variant="info">Cancella voucher</Tooltip>
                                </Button>
                              </td>
                            </tr>
                    }) : '' }
                </tbody>
              </Table>
              { numPages > 1 ?
                <div className='pageListWrapper'>
                  <ul className='pagesList'>
                    { [...Array(numPages)].map(function(obj, i) {
                      let ii = i+1;
                      if (numPages > 5)
                        switch (parseInt(ii)) {
                          case 1:
                          case (page-1):
                          case page:
                          case page+1:
                          case numPages:
                            return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                          default:
                            return '';
                        }
                      else
                        return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                    }) }
                  </ul>
                </div>
              : '' }
            </>
          : <p>Nessuna richiesta per coupon partner presente</p> }
        </Col>
      </Row>
    </Container>
  );
  
}

export default AdminVouchers;