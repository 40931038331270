import React from "react";
import * as FaIcons from "react-icons/fa";
import * as BiIcons from "react-icons/bi";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io";
import * as GiIcons from "react-icons/gi";
import * as RiIcons from "react-icons/ri";
import * as TiIcons from "react-icons/ti";
import * as MdIcons from "react-icons/md";
import * as GrIcons from "react-icons/gr";
import * as PiIcons from "react-icons/pi";
import * as ImIcons from "react-icons/im";
import * as TbIcons from "react-icons/tb";

/* Your icon name from database data can now be passed as prop */
const DynamicIcon = ({ iconName, spaced, size }) => {
    let IconComponent;
    if (FaIcons[iconName])
        IconComponent = FaIcons[iconName];
    if (BiIcons[iconName])
        IconComponent = BiIcons[iconName];
    if (BsIcons[iconName])
        IconComponent = BsIcons[iconName];
    if (IoIcons[iconName])
        IconComponent = IoIcons[iconName];
    if (GiIcons[iconName])
        IconComponent = GiIcons[iconName];
    if (RiIcons[iconName])
        IconComponent = RiIcons[iconName];
    if (TiIcons[iconName])
        IconComponent = TiIcons[iconName];
    if (MdIcons[iconName])
        IconComponent = MdIcons[iconName];
    if (GrIcons[iconName])
        IconComponent = GrIcons[iconName];
    if (PiIcons[iconName])
        IconComponent = PiIcons[iconName];
    if (ImIcons[iconName])
        IconComponent = ImIcons[iconName];
    if (TbIcons[iconName])
        IconComponent = TbIcons[iconName];

    let styleObj = {};
    if (spaced)
        styleObj['margin'] = '0 5px';
    if (size)
        styleObj['fontSize'] = size+'px';

    if (!IconComponent)     // Return a default one
        return <FaIcons.FaBeer />;

    return <IconComponent style={styleObj} />;
};

export default DynamicIcon;