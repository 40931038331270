import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminSlotTypesEdit() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  // FORM
  const { register, handleSubmit, setValue, watch, reset, formState: { errors } } = useForm();
  // DATA
  const [slotType, setSlotType] = useState(null);

  useEffect(() => {
    if (state && state.slot_type_id) {
      axios.post(window.SERVER_URL+'slot_types/get', { id: state.slot_type_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setSlotType(res.data);

        // Setto i campi della tipologia escursione
        let xfields = {};
        for (const [key, val] of Object.entries(res.data))
          if (typeof val !== 'object')
            xfields[key] = val;
        reset(xfields);
      });
    } else {
      navigate('/admin/slot_types');
    }
  }, []);

  const onSubmit = (form_data) => {
    console.log(form_data);
    axios.post(window.SERVER_URL+'slot_types/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }
    }).then(res => {
      console.log(res.data);
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'Tipologia escursione aggiornata',
          html: "La tipologia escursione è stata aggiornata"
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Si è verificato un errore',
          html: "La tipologia escursione non è stata aggiornata<br />Riprova per favore",
          confirmButtonColor: "#314491",
          confirmButtonText: "Ok"
        });
      }
    });
  }
  
  return (
    <Container className="content admin">
      { slotType ? 
        <>
          <Row className="page_title">
            <Col>
              <h1>Tipologia escursione <span>{ slotType.descrizione }</span></h1>
            </Col>
          </Row>
          <Row>
            <Col className='tab_wrapper'>
              <form className="custom_form" onSubmit={handleSubmit(onSubmit)}>
                <input type="hidden" {...register("id", { required: true })} />
                <Row className='panel'>
                  <Col className="custom_form_wrapper">
                    <h4>Dati tipologia escursione</h4>
                    <Row>
                      <Col>
                        <label>Descrizione</label>
                        <input type="text" placeholder="Descrizione" {...register("descrizione", { required: true })} />
                      </Col>
                      <Col>
                        <label>Codice</label>
                        <input type="text" placeholder="Codice" {...register("codice", { required: true })} readOnly={true} />
                      </Col>
                      <Col>
                        <label>URL sito web</label>
                        <input type="text" placeholder="URL sito web" {...register("link_sito")} />
                      </Col>
                    </Row>
                    <Row style={{'marginTop':'20px'}}>
                      <Col>
                        <label>Durata (min.)</label>
                        <input type="number" step="10" placeholder="Durata" {...register("durata", { required: true })} />
                      </Col>
                      <Col>
                        <label>Num guidatori MAX</label>
                        <input type="number" step="1" placeholder="Num. guidatori" {...register("num_guidatori_max", { required: true })} />
                      </Col>
                      <Col>
                        <label>Num passeggeri MAX</label>
                        <input type="number" step="1" placeholder="Num. passeggeri" {...register("num_passeggeri_max", { required: true })} />
                      </Col>
                    </Row>
                    <Row style={{'marginTop':'20px'}}>
                      <Col>
                        <label>Prezzo guidatore (€)</label>
                        <input type="number" step="5" placeholder="Prezzo guidatore" {...register("prezzo_guidatore", { required: true })} />
                      </Col>
                      <Col>
                        <label>Prezzo passeggero (€)</label>
                        <input type="number" step="5" placeholder="Prezzo passeggero" {...register("prezzo_passeggero", { required: true })} />
                      </Col>
                      <Col>
                        <label>Visible dagli utenti</label>
                        <select {...register("visibile", { required: true })}>
                          <option value="">- Scegli -</option>
                          <option value="1">Sì</option>
                          <option value="0">No</option>
                        </select>
                      </Col>
                    </Row>
                    <Button type="submit" variant="success" className="waste_btn">Aggiorna tipologia</Button>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </>
      : '' }
    </Container>
  );
  
}

export default AdminSlotTypesEdit;