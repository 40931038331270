import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// TOLTIP
import { Tooltip } from 'react-tooltip';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminSlotTypes() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  // PAGINAZIONE
  const [page, setPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [count, setCount] = useState(null);
  const [query, setQuery] = useState(null);
  // DATA
  const [slotTypes, setSlotTypes] = useState(null);

  useEffect(() => {
    getPage(
      state && state.page ? state.page : page,
      state && state.query ? state.query : query
    );
  }, []);

  const getPage = (i, query) => {
    let params = {
      page: i
    }
    if (query && query.length >= 3)
      params.search = query;
      
    axios.post(window.SERVER_URL+'slot_types/list', { params }, { headers: {
      'Access-Control-Allow-Origin': '*',
      'Authorization': 'Bearer '+authobj.token
    }}).then(res => {
      console.log(res.data);
      setSlotTypes(res.data);
      setPage(i);
      setNumPages(res.data.pages);
      setCount(res.data.count);
      setQuery(query);
    });
  }

  const handleInputChange = (typed) => {
    console.log(typed);
    getPage(1, typed);
  }
  
  return (
    <Container className="content admin">
      <Row className="page_title">
        <Col>
          <h1>Tipologie escursioni</h1>
        </Col>
      </Row>
      <Row>
        <Col className='tab_wrapper'>
          { slotTypes ? 
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th style={{'width':'50px'}}>ID</th>
                    <th>Nome</th>
                    <th style={{'width':'100px'}}>Durata</th>
                    <th style={{'width':'150px'}}>Prezzo guidatore</th>
                    <th style={{'width':'150px'}}>Prezzo passeggero</th>
                    <th style={{'width':'120px'}}>Num. guid. max</th>
                    <th style={{'width':'120px'}}>Num. pass. max</th>
                    <th>Codice</th>
                    <th>Visibile</th>
                    <th style={{'width':'100px','textAlign':'center'}}>Azioni</th>
                  </tr>
                </thead>
                <tbody>
                  { slotTypes.length ? slotTypes.map((type) => {
                      return <tr key={ type.id }>
                              <td>{ type.id }</td>
                              <td>{ type.descrizione }</td>
                              <td>{ type.durata } min</td>
                              <td>{ parseInt(type.prezzo_guidatore) } €</td>
                              <td>{ parseInt(type.prezzo_passeggero) } €</td>
                              <td>{ type.num_guidatori_max }</td>
                              <td>{ type.num_passeggeri_max }</td>
                              <td>{ type.codice.toUpperCase() }</td>
                              <td>{ type.visibile ? (type.visibile == '1' ? 'Sì' : 'No') : '-' }</td>
                              <td className="actions" style={{'textAlign':'right'}}>
                                { type.link_sito ?
                                  <Button className='type_web' onClick={() => { window.open(type.link_sito); }}>
                                    <DynamicIcon iconName='FaExternalLinkAlt' />
                                    <Tooltip anchorSelect=".type_web" place="top" variant="info">Vedi sul sito</Tooltip>
                                  </Button>
                                : '' }
                                <Button className='type_det' onClick={() => { navigate('/admin/slot_types/edit', { state: { slot_type_id: type.id, page: page, query: query } }) }}>
                                  <DynamicIcon iconName='IoIosArrowForward' />
                                  <Tooltip anchorSelect=".type_det" place="top" variant="info">Vedi dettaglio tipologia</Tooltip>
                                </Button>
                                {/* <Button onClick={() => { }} className="delete">
                                  <DynamicIcon iconName='FaTrash' />
                                  <Tooltip anchorSelect=".delete" place="top" variant="info">Cancella type</Tooltip>
                                </Button> */}
                              </td>
                            </tr>
                    }) : '' }
                </tbody>
              </Table>
              { numPages > 1 ?
                <div className='pageListWrapper'>
                  <ul className='pagesList'>
                    { [...Array(numPages)].map(function(obj, i) {
                      let ii = i+1;
                      if (numPages > 5)
                        switch (parseInt(ii)) {
                          case 1:
                          case (page-1):
                          case page:
                          case page+1:
                          case numPages:
                            return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                          default:
                            return '';
                        }
                      else
                        return <li key={'pag'+ii} className={ ii == page ? 'active' : '' } onClick={() => { getPage(ii, query) }}>{ii}</li>
                    }) }
                  </ul>
                </div>
              : '' }
            </>
          : <p>Nessuna tipologia di escursione presente</p> }
        </Col>
      </Row>
    </Container>
  );
  
}

export default AdminSlotTypes;