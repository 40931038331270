import React from 'react';
// REACT ROUTER
import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// CONTEXTS
import { AuthProvider, RequireAuth } from './components/contexts/AuthContext';
import { GlobProvider } from './components/contexts/GlobContext';
// LAYOUT
import Main from './components/main.js';
// FRONT
import Init from './components/pages/init';
import Voucher from './components/pages/voucher';
import Calendar from './components/pages/calendar';
import Book from './components/pages/book';
import Manage from './components/pages/manage';
import Thank from './components/pages/thank';
import Track from './components/pages/track';
// ADMIN
import AdminLogin from './components/pages/admin/login';
import AdminDashboard from './components/pages/admin/dashboard';
import AdminVouchers from './components/pages/admin/vouchers';
import AdminVouchersView from './components/pages/admin/vouchers_view';
import AdminSlotTypes from './components/pages/admin/slot_types';
import AdminSlotTypesEdit from './components/pages/admin/slot_types_edit';
import AdminReservations from './components/pages/admin/reservations';
import AdminReservationsAdd from './components/pages/admin/reservations_add';
import AdminReservationsTrash from './components/pages/admin/reservations_trash';
import AdminSlotsView from './components/pages/admin/slots_view';
import AdminSlotsAdd from './components/pages/admin/slots_add';
import AdminSlotsGenerate from './components/pages/admin/slots_generate';
import AdminPaylinksAdd from './components/pages/admin/paylinks_add';

const container = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

root.render(
    <AuthProvider>
        <GlobProvider>
            <BrowserRouter>
                <Routes>
                    <Route element={<Main />}>
                        {/* FRONT */}
                        <Route path="/" element={<Init  />} />
                        <Route path="/voucher" element={<Voucher  />} />
                        <Route path="/calendar" element={<Calendar  />} />
                        <Route path="/calendar/v/:voucherCode" element={<Calendar  />} />
                        <Route path="/calendar/e/:escTypeOnly" element={<Calendar  />} />
                        <Route path="/book" element={<Book  />} />
                        <Route path="/manage" element={<Manage  />} />
                        <Route path="/manage/:hashAccess" element={<Manage  />} />
                        <Route path="/thank" element={<Thank  />} />
                        <Route path="/track/:resID" element={<Track  />} />
                        {/* ADMIN */}
                        <Route path="/admin" element={<AdminLogin  />} />
                        <Route path="/admin/dashboard" element={<RequireAuth><AdminDashboard /></RequireAuth>} />
                        <Route path="/admin/dashboard/:initDate" element={<RequireAuth><AdminDashboard /></RequireAuth>} />
                        <Route path="/admin/vouchers" element={<RequireAuth><AdminVouchers /></RequireAuth>} />
                        <Route path="/admin/vouchers/view" element={<RequireAuth><AdminVouchersView /></RequireAuth>} />
                        <Route path="/admin/slot_types" element={<RequireAuth><AdminSlotTypes /></RequireAuth>} />
                        <Route path="/admin/slot_types/edit" element={<RequireAuth><AdminSlotTypesEdit /></RequireAuth>} />
                        <Route path="/admin/reservations" element={<RequireAuth><AdminReservations /></RequireAuth>} />
                        <Route path="/admin/reservations/add" element={<RequireAuth><AdminReservationsAdd /></RequireAuth>} />
                        <Route path="/admin/reservations/trash" element={<RequireAuth><AdminReservationsTrash /></RequireAuth>} />
                        <Route path="/admin/slots/view" element={<RequireAuth><AdminSlotsView /></RequireAuth>} />
                        <Route path="/admin/slots/add" element={<RequireAuth><AdminSlotsAdd /></RequireAuth>} />
                        <Route path="/admin/slots/generate" element={<RequireAuth><AdminSlotsGenerate /></RequireAuth>} />
                        <Route path="/admin/paylinks/add" element={<RequireAuth><AdminPaylinksAdd /></RequireAuth>} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </GlobProvider>
    </AuthProvider>
);