/* global gtag */
import React, { useState, useEffect, useContext } from 'react';
// AUTH
import { AuthContext } from '../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation, useParams, redirect } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// AXIOS
import axios from 'axios';

function Track() {
  // AUTH, STATE, NAVIGATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { state } = useLocation();
  let navigate = useNavigate();
  const { resID } = useParams();

  useEffect(() => {
    if (resID) {
      axios.post(window.SERVER_URL+'reservations/get', { id: resID }, {
        headers: {
          'Access-Control-Allow-Origin': '*'
        }
      }).then(res => {
        let importo = res.data.pagamento_importo;
        if (typeof gtag === 'function') {
          gtag('event', 'conversion', {
            send_to: 'AW-665714204/xUFmCKKdluwZEJz8t70C',
            value: importo,
            currency: 'EUR',
            transaction_id: res.data.codice,
            event_callback: callback,
          });
        }
      });
    } else {
      navigate('/thank');
    }
  }, []);

  const callback = () => {
    navigate('/thank');
  };
  
  return;

}

export default Track;