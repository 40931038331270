import React, { useContext, useState, useEffect } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate } from "react-router-dom";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// REACT HOOK FORM
import { useForm } from "react-hook-form";
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// AXIOS
import axios from 'axios';

function AdminLogin() {
  // AUTH, STATE, NAVIGATION
  const { signin, lingue } = useContext(AuthContext);
  // FORM
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  // SWEETALERT
  const MySwal = withReactContent(Swal);

  useEffect(() => {
  }, []);

  let navigate = useNavigate();

  const onSubmit = (form_data) => {
    /* FAKE LOGIN */
    // signin({'success':'true', 'token':'dsada39hnf', 'name':'Fake User', 'user_id':1, 'role':'Administrator', 'group_id':1}, navigate('/admin/dashboard'));
     
    console.log("DATI LOGIN");
    console.log(form_data);
    axios.post(window.SERVER_URL+'users/login', form_data, { headers: {
      'Access-Control-Allow-Origin': '*'
    }}).then(res => {
      console.log("RISPOSTA LOGIN");
      console.log(res.data);
      
      if (res.data.success && res.data.token) {
        res.data.email = form_data.email;
        signin(res.data, navigate('/admin/dashboard'));
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: 'Autenticazione fallita. Controlla i tuoi dati e riprova.'
        });
      }        
    });
  }

  return (
    <Container className='admin' id='login_form_wrapper'>
      <Row>
        <Col>
          <img id="logo" src='https://escursionimotoslitte.it/img/escursioni-motoslitte-logo.svg' />
          <br />
          <form className="custom_form" id="login_form" onSubmit={handleSubmit(onSubmit)}>
            <input type="text" placeholder="Email" {...register("email", { required: true })} />
            {errors.email && <span>Devi inserire il tuo indirizzo email</span>}
            <input type="password" placeholder="Password" {...register("password", { required: true })} />
            {errors.password && <span>Devi inserire la password</span>}
            <Button type="submit" variant="primary" className="waste_btn">Accedi / Login</Button>
          </form>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminLogin;