import React, { useContext, useState, useEffect, useRef } from 'react';
// AUTH
import { AuthContext } from '../../contexts/AuthContext';
// REACT ROUTER
import { useNavigate, useLocation } from 'react-router-dom';
// I18N
import { useTranslation } from 'react-i18next';
// AXIOS
import axios from 'axios';
// CUSTOM HOOKS
import { useHelpers } from '../../hooks/useHelpers';
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// TOLTIP
import { Tooltip } from 'react-tooltip';
// DYNAMIC ICON
import DynamicIcon from '../../partials/DynamicIcon';

function AdminSlotsView() {
  // AUTH, STATE, NAVIGATION, TRANSLATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { t } = useTranslation();
  const { state } = useLocation();
  let navigate = useNavigate();
  // CUSTOM HOOKS
  const { getDayName, copyOnClipboard, getOccupationClass, handleReservationDelete, handleReservationPayment, sendMail } = useHelpers();
  // DATA
  const [slot, setSlot] = useState(null);

  useEffect(() => {
    if (state && state.slot_id) {  
      axios.post(window.SERVER_URL+'slots/get', { id: state.slot_id }, { headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer '+authobj.token
      }}).then(res => {
        console.log(res.data);
        setSlot(res.data);
      });
    } else {
      navigate('/admin');
    }
  }, [globalRefresh]);

  const handleDelSlot = (slot_id) => {
    Swal.fire({
      icon: 'warning',
      title: 'Sei sicuro di voler cancellare questa escursione?',
      html: "<b>Fai attenzione!</b> L'operazione non è reversibile.<br />Verranno cancellate anche <b>tutte le prenotazioni</b> presenti per questa escursione.",
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Conferma',
      denyButtonText: 'Ci ho ripensato',
      confirmButtonColor: "#314491",
      cancelButtonColor: "#cb4f2c"
    }).then((result) => {
      if (result.isConfirmed) {
        axios.post(window.SERVER_URL+'slots/delete', { id: slot_id }, { headers: {
          'Access-Control-Allow-Origin': '*',
          'Authorization': 'Bearer '+authobj.token
        }}).then(res => {
          if (res.data.success) {
            Swal.fire({
              icon: 'success',
              title: 'Escursione cancellata',
              html: "L'escursione è stata correttamente cancellata"
            }).then((result) => {
              navigate(`/admin/dashboard/${slot.inizio.substr(0,10)}`);
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Si è verificato un errore',
              html: "Non è stato possibile cancellare l'escursione, riprova per favore"
            });
          }
        });
      }
    });
  }
  
  return (
    <Container className="content admin">
      <Row className="page_title">
        <Col>
          <h1>Dettaglio escursione <span>{ slot ? slot.inizio.substr(0, 10).split("-").reverse().join("/")+' '+slot.inizio.substr(11,5) : '' }</span></h1>
        </Col>
      </Row>
      <Row>
        <Col className='tab_wrapper'>
          { slot ? 
            <>
              <Table responsive className='detail'>
                <tbody>
                  <tr>
                    <td>ID</td>
                    <td>{ slot.id }</td>
                  </tr>
                  <tr>
                    <td>Tipo escursione</td>
                    <td>{ slot.slot_type.descrizione }</td>
                  </tr>
                  <tr>
                    <td>Inizio escursione</td>
                    <td>{ getDayName(slot.inizio.substr(0, 10))+' '+slot.inizio.substr(0, 10).split("-").reverse().join("/")+' '+slot.inizio.substr(11,5) }</td>
                  </tr>
                  <tr>
                    <td>Fine escursione</td>
                    <td>{ getDayName(slot.fine.substr(0, 10))+' '+slot.fine.substr(0, 10).split("-").reverse().join("/")+' '+slot.fine.substr(11,5) }</td>
                  </tr>
                  {/* <tr>
                    <td>Voucher ammessi</td>
                    <td>{ slot.con_voucher == 1 ? 'Sì' : 'No' }</td>
                  </tr> */}
                  <tr>
                    <td>Num. massimo voucher</td>
                    <td>{ slot.max_voucher ? slot.max_voucher : 'Illimitato' }</td>
                  </tr>
                </tbody>
              </Table>
              <Row className='admin_detail'>
                <Col>
                  Livello occupazione<br />
                  <div className={'tag '+getOccupationClass(slot.utenti.occupazione)} style={{'display':'inline-block','margin':'0 5px 0 0'}}></div> <b>{ slot.utenti.occupazione }%</b>
                </Col>
                <Col>
                  Numero guidatori<br />
                  <div className='slot_person_wrapper'>
                    { Array.from({ length: slot.utenti.guidatori.presenti }).map((_, index) => (
                      <div key={index} className="slot_person occ"></div>
                    ))}
                    { Array.from({ length: slot.utenti.guidatori.disponibili }).map((_, index) => (
                      <div key={index} className="slot_person disp"></div>
                    ))}
                  </div>
                  <b>{ slot.utenti.guidatori.presenti } prenotati / { slot.utenti.guidatori.disponibili } disponibili</b>
                </Col>
                <Col>
                  Numero passeggeri<br />
                  <div className='slot_person_wrapper'>
                    { Array.from({ length: slot.utenti.passeggeri.presenti }).map((_, index) => (
                      <div key={index} className="slot_person occ"></div>
                    ))}
                    { Array.from({ length: slot.utenti.passeggeri.disponibili }).map((_, index) => (
                      <div key={index} className="slot_person disp"></div>
                    ))}
                  </div>
                  <b>{ slot.utenti.passeggeri.presenti } prenotati / { slot.utenti.passeggeri.disponibili } disponibili</b>
                </Col>
              </Row>
              <br />
              <h4 style={{'textAlign':'left'}}>Prenotazioni</h4>
              { slot.reservations && slot.reservations.length ? 
                <Table responsive>
                  <thead>
                    <tr>
                      <th style={{'width':'50px'}}>Stato</th>
                      <th>ID</th>
                      <th style={{'width':'150px'}}>Data prenotazione</th>
                      <th>Codice</th>
                      <th>Nome</th>
                      <th>Telefono</th>
                      <th>E-mail</th>
                      <th style={{'width':'100px','textAlign':'center'}}>Importo</th>
                      <th style={{'width':'40px'}}><DynamicIcon iconName='GiSteeringWheel' size="24" /></th>
                      <th style={{'width':'40px'}}><DynamicIcon iconName='MdEventSeat' size="24" /></th>
                      <th style={{'width':'160px','textAlign':'center'}}>Azioni</th>
                    </tr>
                  </thead>
                  <tbody>
                    { slot.reservations.length ? slot.reservations.map((reservation) => {
                        return <tr key={ reservation.id } className={ reservation.stato == 'inprogress' ? 'inprogress' : '' }>
                                <td><span className={'tag '+reservation.stato}></span></td>
                                <td>{ reservation.id }</td>
                                <td>{ reservation.created_at ? reservation.created_at.substr(0, 10).split("-").reverse().join("/")+' '+reservation.created_at.substr(11,5) : '-' }</td>
                                <td>{ reservation.codice.toUpperCase() }</td>
                                <td>{ reservation.titolare_nome && reservation.titolare_cognome ? reservation.titolare_nome+' '+reservation.titolare_cognome : '?' }</td>
                                <td>{ reservation.titolare_telefono ? reservation.titolare_telefono : '?' }</td>
                                <td>{ reservation.titolare_email ? reservation.titolare_email : '?' }</td>
                                <td style={{'textAlign':'right'}}>{ reservation.pagamento_importo ? parseInt(reservation.pagamento_importo)+' €' : '-' }</td>
                                <td style={{'textAlign':'center'}}>{ reservation.utenti ? reservation.utenti.guidatori : '-' }</td>
                                <td style={{'textAlign':'center'}}>{ reservation.utenti ? reservation.utenti.passeggeri : '-' }</td>
                                <td className="actions" style={{'textAlign':'right'}}>
                                  { reservation.stato != 'inprogress' ?
                                    <>
                                      <Button className='reservation_det' onClick={() => { navigate('/admin/reservations/add', { state: { reservation_id: reservation.id } }) }}>
                                        <DynamicIcon iconName='IoIosArrowForward' />
                                        <Tooltip anchorSelect=".reservation_det" place="top" variant="info">Vedi prenotazione</Tooltip>
                                      </Button>
                                      <Button className='reservation_link' onClick={() => { copyOnClipboard(window.BOOKING_URL+'/manage/'+btoa(reservation.titolare_email+'|'+reservation.codice), "Il link è stato copiato negli appunti") }}>
                                        <DynamicIcon iconName='FaLink' />
                                        <Tooltip anchorSelect=".reservation_link" place="top" variant="info">Copia link gestione</Tooltip>
                                      </Button>
                                      <Button className='reservation_pay' onClick={() => { handleReservationPayment(reservation) }}>
                                        <DynamicIcon iconName='IoLogoEuro' />
                                        <Tooltip anchorSelect=".reservation_pay" place="top" variant="info">Gestisci pagamento</Tooltip>
                                      </Button>
                                      <Button className='reservation_mail' onClick={() => sendMail(reservation.id) }>
                                        <DynamicIcon iconName='RiMailSendLine' />
                                        <Tooltip anchorSelect=".reservation_mail" place="top" variant="info">Invia comunicazione</Tooltip>
                                      </Button>
                                    </>
                                  : '' }
                                  <Button className='reservation_del' variant="danger" onClick={() => handleReservationDelete(reservation.id, reservation.titolare_email) }>
                                    <DynamicIcon iconName='FaTrash' />
                                    <Tooltip anchorSelect=".reservation_del" place="top" variant="info">Annulla prenotazione</Tooltip>
                                  </Button>
                                </td>
                              </tr>
                      }) : '' }
                  </tbody>
                </Table>
              : <p className='alert'>Non sono ancora presenti prenotazioni per questa escursione</p> }
              <br />
              <Row>
                <Col className='action_bar bottom'>
                  <Button onClick={() => { navigate('/admin/reservations/add', { state: { slot_id: slot.id } }) }}><DynamicIcon iconName='FaPlus' /> Aggiungi prenotazione</Button>
                  <Button variant="danger" onClick={() => handleDelSlot(slot.id)}><DynamicIcon iconName='FaTrash' /> Cancella escursione</Button>
                </Col>
              </Row>
            </>
          : '' }
        </Col>
      </Row>
    </Container>
  );
  
}

export default AdminSlotsView;