import React, { useState, useEffect, useContext } from 'react';
// CONTEXTS
import { AuthContext } from '../contexts/AuthContext';
import { GlobContext } from '../contexts/GlobContext';
// REACT ROUTER
import { useNavigate } from 'react-router-dom';
// CUSTOM HOOKS
import { useHelpers } from '../hooks/useHelpers';
// AXIOS
import axios from 'axios';
// REACT HOOK FORM
import { useForm, useFieldArray } from "react-hook-form";
// BOOTSTRAP
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// REACT SWEETALERT
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// DYNAMIC ICON
import DynamicIcon from '../partials/DynamicIcon';
import FrontHeader from '../partials/header_front';
import { setWeekYearWithOptions } from 'date-fns/fp';

function Voucher() {
  // AUTH, STATE, NAVIGATION
  const { authobj, signout, globalRefresh, setGlobalRefresh } = useContext(AuthContext);
  const { wait, setWait, waitPercent, setWaitPercent } = useContext(GlobContext);
  let navigate = useNavigate();
  // FORM
  const { register, control, handleSubmit, trigger, getValues, setValue, reset, formState: { errors } } = useForm();
  const { register: register2, control: control2, handleSubmit: handleSubmit2, trigger: trigger2, getValues: getValues2, setValue: setValue2, reset: reset2, formState: { errors: errors2 } } = useForm();
  // DATA
  const [voucherType, setVoucherType] = useState(null);
  const [partners, setPartners] = useState(null);

  useEffect(() => {
    setWait(true);
    axios.post(window.SERVER_URL+'voucher_partners/list', {}, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(res => {
      console.log(res.data);
      setPartners(res.data);
      setWait(false);
    });
  }, [globalRefresh]);

  const checkHolitalyVoucher = (form_data) => {
    setWait(true);
    axios.post(window.SERVER_URL+'coupons/check', { codice: form_data.codice }, {
      headers: {
        'Access-Control-Allow-Origin': '*'
      }
    }).then(res => {
      console.log(res.data);
      setWait(false);
      if (res.data.success) { // il codice è stato validato
        axios.post(window.SERVER_URL+'coupons/get', { codice: res.data.codice }, {
          headers: {
            'Access-Control-Allow-Origin': '*'
          }
        }).then(resc => {
          Swal.fire({
            icon: 'success',
            title: 'Il buono regalo è valido!',
            html: `Puoi utilizzare il tuo buono regalo per effettuare una prenotazione.<br><br>
                   Il buono è valido fino al <b>${resc.data.expire_at.substr(0,10).split("-").reverse().join("/")} ${resc.data.expire_at.substr(11,5)}</b><br><br>
                   Ricorda che puoi usarlo per prenotare un'escursione che si svolge entro quella data/ora.`,
            confirmButtonColor: "#314491",
            confirmButtonText: 'Prenoto la mia escursione',
          }).then((result) => {
            if (result.isConfirmed) {
              navigate('/calendar', { state: { voucher: form_data.codice } });
            }
          });
        });
      } else {  // il codice non è valido
        Swal.fire({
          icon: 'error',
          title: 'Il codice immesso non è valido',
          html: `<h5>Cosa potrebbe essere successo:</h5>
                - Il codice immesso <b>non è un buono regalo valido</b><br>
                - Il buono regalo <b>è scaduto</b><br>
                - Il buono regalo <b>è già stato utilizzato</b>
                <br><br>
                Assicurati di non aver inserito spazi vuoti tra i caratteri.<br>
                Controlla e riprova per favore.`,
        });
      }
    });
  }

  const checkOutherVoucher = (form_data) => {
    setWait(true);
    console.log(form_data);

    axios.post(window.SERVER_URL+'vouchers/save', form_data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      setWait(false);
      if (res.data.success) {
        Swal.fire({
          icon: 'success',
          title: 'La tua richiesta è stata inviata',
          html: "Se il tuo coupon è valido, <b>entro 24 ore riceverai un'e-mail</b> con le istruzioni e il nuovo codice da utilizzare. In caso contrario <b>verrai contattato</b> per un approfondimento."
        }).then((result) => {
          navigate('/');
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Errore',
          text: "Non è stato possibile inviare la tua richiesta, riprova per favore"
        });
      }
    });
  }
  
  return (
    <Container className="front_content">
      <FrontHeader />
      <Row id='subheader'>
        <Col>
          <h2>Prenota con buono regalo</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={voucherType ? (voucherType == 'holitaly' ? 'init_box collapsed active' : 'init_box collapsed') : 'init_box'} onClick={() => { setVoucherType('holitaly') }}>
            Ho un buono regalo<br />EscursioniMotoslitte.it
          </div>
        </Col>
        <Col>
          <div className={voucherType ? (voucherType == 'other' ? 'init_box collapsed active' : 'init_box collapsed') : 'init_box'} onClick={() => { setVoucherType('other') }}>
            Ho un buono regalo<br />di una piattaforma partner
          </div>
        </Col>
      </Row>
      { voucherType && voucherType == 'holitaly' ?
        <Row className='panel voucher_panel'>
          <Col>
            <h2>Hai scelto: buono regalo Holitaly</h2>
            <h4>Per continuare, immetti il codice che trovi sul tuo buono</h4>
            <form className="custom_form" onSubmit={handleSubmit(checkHolitalyVoucher)}>
              <input type="text" placeholder="Codice buono regalo" className="voucher_input" {...register("codice", { required: true })} style={{'width':'320px'}} />
              <Button type="submit" variant="success" className="waste_btn voucher_btn">Controlla validità</Button>
            </form>
          </Col>
        </Row>
      : '' }
      { voucherType && voucherType == 'other' ?
        <Row className='panel voucher_panel'>
          <Col>
            <h2>Hai scelto: buono regalo di piattaforma partner</h2>
            <h4>Per continuare, compila la form qui sotto</h4>
            <p>Ti chiediamo di <b>compilare debitamente tutti i campi</b> perché abbiamo bisogno di tutte le informazioni richieste per verificare la correttezza e la validità del tuo buono regalo.</p>
            <p>Una volta richiesta la validazione, lo staff di Holitaly si riserva <b>fino a 24 ore di tempo</b> per il controllo.<br />
                Se il tuo voucher è valido, ti verrà <b>inviato via e-mail un link</b> che potrai usare per effettuare una prenotazione con lo <b>sconto impostato automaticamente</b>.<br />
                In caso contrario, verrai contattato al più presto per un ulteriore approfondimento.</p>
            <br />
            <form className="custom_form" onSubmit={handleSubmit2(checkOutherVoucher)}>
              <Row>
                <Col>
                  <label>Dove è stato acquistato il buono</label>
                  <select {...register2("voucher_partner_id", { required: true })}>
                    <option value="">-</option>
                    { partners && partners.length ? partners.map((partner) => 
                      <option key={partner.id} value={partner.id}>{ partner.nome }</option>
                    ) : '' }
                  </select>
                </Col>
                <Col>
                  <label>Codice del buono</label>
                  <input type="text" placeholder="Codice buono regalo" className='voucher_input' {...register2("codice", { required: true })} />
                </Col>
                <Col>
                  <label>Data scadenza buono</label>
                  <input type="date" min={new Date().toISOString().split('T')[0]} placeholder="Scadenza buono regalo" {...register2("scadenza", { required: true })} />
                </Col>
                <Col>
                  <label>Foto completa  e leggibile del buono</label>
                  <input type="file" name='pic' {...register2('immagine', { required: true })} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>Nome e cognome</label>
                  <input type="text" placeholder="Il tuo nome e cognome" {...register2("nome", { required: true })} />
                </Col>
                <Col>
                  <label>Recapito telefonico</label>
                  <input type="text" placeholder="Un tuo recapito telefonico" {...register2("telefono", { required: true })} />
                </Col>
                <Col>
                  <label>Indirizzo e-mail</label>
                  <input type="email" placeholder="Indirizzo e-mail" {...register2("email", { required: true })} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit" variant="success" className="waste_btn voucher_btn">Richiedi validazione</Button>
                </Col>
              </Row>
            </form>
          </Col>
        </Row>
      : '' }
    </Container>
  );

}

export default Voucher;